/*
COLORES A REEMPLAZAR

CONTENIDO rgb(153,153,153)
PPAL AZUL rgb(255,157,0)
TITULOS rgb(0,45,90)
TEXTO GLOBAL rgb(153,153,153)
FONDO DE ATRAS DEL BANNER EVENTO rgb(0,98,196)
FONDO DE LA PAGINA EVENTO body#evento{background: rgb(250,250,250);}
*/
@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto/Roboto-Regular.ttf');
}

/* Fonts App */
/* MONTSERRAT */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-RegularItalic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: lighter;
  font-style: italic;
}

/*OPEN SANS */
@font-face {
  font-family: 'Open-Sans';
  src: url('../fonts/Open_Sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../fonts/Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../fonts/Open_Sans/OpenSans-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Open-Sans';
  src: url('../fonts/Open_Sans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: lighter;
  font-style: italic;
}

/* Oswald */
@font-face {
  font-family: 'Oswald';
  src: url('../fonts/Oswald/static/Oswald-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/Oswald/static/Oswald-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/Oswald/static/Oswald-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/Oswald/static/Oswald-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

/* MONTSERRAT */
@font-face {
  font-family: 'Playfair';
  src: url('../fonts/Playfair_Display/PlayfairDisplay-Black.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair';
  src: url('../fonts/Playfair_Display/PlayfairDisplay-BlackItalic.ttf')
    format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair';
  src: url('../fonts/Playfair_Display/PlayfairDisplay-Bold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair';
  src: url('../fonts/Playfair_Display/PlayfairDisplay-BoldItalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair';
  src: url('../fonts/Playfair_Display/PlayfairDisplay-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair';
  src: url('../fonts/Playfair_Display/PlayfairDisplay-RegularItalic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* Raleway */
@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-LightItalic.ttf') format('truetype');
  font-weight: lighter;
  font-style: italic;
}

/* Roboto */
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: lighter;
  font-style: italic;
}

body {
  font-family:"Open-Sans";
}

.color-texto-global {
  color: rgb(153, 153, 153);
}
.color-texto-contenido {
  color: rgb(153, 153, 153);
}
.color-titulos {
  color: var(--c4);
  /*color: rgb(0, 45, 90);*/
}
.color-sub-titulos {
  color: var(--c4);
  /*color: rgb(0, 45, 90);*/
}
/* .color-b{color: rgb(255,255,255);} */
.color-ppal {
  color: var(--c1) !important;
}

#pCiudadNombre {
  color: var(--c4) !important;
}

#spanRecintoNombre {
  color: var(--c5) !important;
}

#labelSelect {
  color: var(--c1) !important;
  border-color: var(--c1) !important;
}

#pFecha {
  color: var(--c4) !important;
}

#pHora {
  color: var(--c4) !important;
}

.color-icon-c {
  color: rgb(255, 157, 0);
}

.add-ent {
  color: var(--c1) !important;
  border-color: var(--c1) !important;
}
.rem-ent {
  color: var(--c1) !important;
  border-color: var(--c1) !important;
}

.color-texto-contenido {
  color: var(--c5) !important;
}

body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r p {
  color: var(--c1) !important;
}

body#evento #main .wrap-evento .monedasCheck{
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0 30px;
}
body#evento #main .wrap-evento .monedasCheck a{
  margin-right: 50px;
  display: flex;
  position: relative;
  padding-left: 25px;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}
body#evento #main .wrap-evento .monedasCheck a:before{
  content: "";
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border:solid 1px #909090;
  left: 0;
  top: 50%;
  margin-top: -8px;
}
body#evento #main .wrap-evento .monedasCheck a.active:after{
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1B5689;
  left: 3px;
  top: 50%;
  margin-top: -5px;
}
body#evento #main .wrap-evento .monedasCheck a img{
  margin-right: 5px;
  max-width: 20px;
}
body#evento #main .wrap-evento .monedasCheck a span{
  color: var(--c5) !important;
}


body#evento .bg-top,
.bg-top-sm {
  background: #E9EBED;
}

body#evento .bg-ppal,
.bg-ppal-sm {
  background: #E9EBED;

  z-index: 1000;
}

#mapa-boletos-wrap .wrap-boletos .boletos .content .item {
  color: rgb(0, 0, 0);
}

.bg-disabled {
  background: rgb(0, 98, 196);
}
.bg-ppal {
  background: rgb(0, 98, 196);
}
.bg-secundario {
  background: var(--c4);
}

.subText {
  line-height: 20px !important;
  text-align: left !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  max-width: 270px;
}

.hover-text-ppal:hover {
  color: var(--c1);
}
.hover-content:hover {
  color: rgb(153, 153, 153);
}
.hover-text-b:hover {
  color: rgb(255, 255, 255);
}
.hover-text-titulos:hover {
  color: rgb(0, 98, 196);
}

body#evento .calendar .wrap-opciones article.agotado:hover .dir p {
  color: rgb(0, 45, 90);
}
body#evento .calendar .wrap-opciones article.agotado:hover .fecha p {
  color: rgb(0, 45, 90);
}

body#evento .calendar .wrap-opciones article:hover .dir p {
  color: var(--c1) !important;
}
body#evento .calendar .wrap-opciones article:hover .dir #spanRecintoNombre {
  color: var(--c1) !important;
}

body#evento .calendar .wrap-opciones article:hover .fecha p {
  color: var(--c1) !important;
}
body#evento .calendar .wrap-opciones article:hover .fecha #secondL {
  color: var(--c1) !important;
}

#classPrecioBase {
  padding-right: 20px !important;
  width: 30% !important;
  text-align: center !important;
}

.hover-ppal:hover {
  background: rgb(255, 157, 0);
}
.hover-secundario:hover {
  background: rgb(255, 157, 0);
}

.btn-ppal {
  background: #ABAAB2;
  border: none;
  height: 45px;
  font-family:var(--font) !important;
}
.btn-ppal.active {
  background: var(--c1) !important;
  color: white;
}
.btn-ppal.active:hover {
  background: rgb(0, 98, 196);
}

.wrap-pago .wrap-resumen .promo p i,
.wrap-pago .resumen .donaciones .btns.multiple.show-input > div:nth-child(3) i,
.wrap-pago .wrap-resumen .tickets article .ticket > i.icon-cerrar {
  color: rgb(153, 153, 153);
}

.descp-corta {
  padding: 15px 25px;
  border-radius: 8px;
  background: rgba(214,214, 214, .2);
}
.descp-corta h5{
  color: var(--c1);
  font-weight: bold;
  margin-top: 0;
  font-size: 16px;
}
.descp-corta p{
  color: var(--c4);
  font-weight: bold !important;
  font-size: 14px;
}
.btn-light {
  background: none;
  border: solid 1px var(--c1);
  color: var(--c1);
}
.btn-light.inactive {
  background: #f8f8fa !important;
  color: #abaab2;
  border: none;
}

#tabla-boletos tbody tr td .ctrl-cant button {
  color: rgb(255, 157, 0);
  border: solid 2px rgb(255, 157, 0);
  background: rgb(255, 255, 255);
}

#tabla-boletos tbody tr td .ctrl-cant button#add-ent:hover,
#tabla-boletos tbody tr.activo td .ctrl-cant button#rem-ent:hover {
  color: rgb(255, 255, 255);
  background: rgb(255, 157, 0);
}

.wrap-pago .wrap-resumen .tickets article .ticket {
  color: rgb(0, 0, 0);
}

.wrap-pago .resumen .donaciones .btns a {
  color: rgb(153, 153, 153);
  border: solid 1px rgb(216, 216, 216);
}

.wrap-pago .resumen .donaciones .btns.multiple > div:nth-child(2) a.active,
.wrap-pago .resumen .donaciones .btns.multiple > div:nth-child(2) a:hover {
  background: var(--c1);
  border: solid 1px var(--c1);
  color: rgb(255, 255, 255);
}

.tooltip-term {
  background: rgb(0, 45, 90);
  color: rgb(255, 255, 255);
}
.tooltip-term:after {
  border-top: solid 10px rgb(0, 45, 90);
}

#wrap-pago .info-pago .metodos article .wrap.selected {
  /* border: 1px solid var(--c1); */
}

body footer,
#footer-m {
  background: #363B46;
}
.header-ev {
  background: var(--c6);
}

.header-confirm .header-ev {
  background: var(--c2) !important;
}
.header-confirm {
  background: var(--c2) !important;
}
/*confirmacion*/
.confirmacion header,
.confirmacion header.fixed {
  background: var(--c1);
}
/*confirmacion*/

/*calendario*/

/*color dia evento seleccionado */
.calendar .wrap-opciones.datepicker .ui-state-active,
.calendar .wrap-opciones.datepicker .ui-widget-content .ui-state-active,
.calendar .wrap-opciones.datepicker .ui-widget-header .ui-state-active,
.calendar .wrap-opciones.datepicker a.ui-button:active,
.calendar .wrap-opciones.datepicker .ui-button:active,
.calendar .wrap-opciones.datepicker .ui-button.ui-state-active:hover {
  /*
	font-weight: bold !important;
	color: rgb(0,0,0) !important;
	border-radius: 2px;
	*/
}
/*color dias eventos*/
.calendar .wrap-opciones.datepicker .ui-datepicker td.event span,
.ui-datepicker td.event a {
  background: rgb(250, 250, 250) !important;
  color: var(--c1) !important;
  font-weight: bold !important;
  position: relative;
}

/*
.calendar .wrap-opciones.datepicker .ui-datepicker td.event span, .ui-datepicker td.event.multiple a:after{
	content: "";
	display: block;
	width: 3px;
	height: 3px;
	position: absolute;
	bottom: 2px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
	border-radius: 50%;
	background: rgb(255,157,0);
}
*/

/*color dias*/
.calendar .wrap-opciones.datepicker .ui-datepicker th {
  color: #b5b5b5;
}
/*titulo de los meses y color de las fechas*/
.ui-widget-header,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  color: var(--c4);
}
/*calendario*/

/*evento*/
body#evento .calendar .wrap-opciones article .label,
body#evento #main .wrap-evento .info-evento .label {
  color: var(--c1);
  border: solid 1px var(--c1);
  text-transform: uppercase;
}
/*evento banner fijo y movil*/
header.fixed .banner-evento .info-evento .datos .fecha,
header.fixed .banner-evento .info-evento .datos .title {
  color: rgb(255, 255, 255) !important;
}
@media (max-width: 576px) {
  header .banner-evento .info-evento .datos .title {
    text-transform: uppercase;
  }

  #eventBanner {
    color: var(--c7) !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
  }
}
/*spinner styles*/

@keyframes ldio-arie1i327iv {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-arie1i327iv div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 10px solid var(--c1);
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-arie1i327iv div {
  animation: ldio-arie1i327iv 1s linear infinite;
  top: 50px;
  left: 50px;
}
.loadingio-spinner-rolling-k94er4xvtw {
  width: 64px;
  height: 64px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loader2 {
  width: 64px;
  height: 64px;
  display: block !important;
  overflow: hidden;
  background: none;
  margin: 0px auto !important;
}
.ldio-arie1i327iv {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.64);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-arie1i327iv div {
  box-sizing: content-box;
}

.spinner-main {
  width: 100px;
  height: 100px;
  margin: 300px auto;
  text-align: center;
}
.great_spinner{
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2000;
}
.spinner2 {
  width: 200px !important;
  height: 100px;
  margin: 300px auto;
}

.container-header-home {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-header-home #lang{
  position: relative;
  z-index: ;
}
.container-header-home #lang button{
  background: none;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
}
.container-header-home #lang button i{
  color: #1B5689;
  font-size: 21px;
  margin-left: 10px;
}
.container-header-home #lang section{
  min-width: 130px;
  border-radius: 5px;
  background: #fff;
  position: absolute;
  top: 30px;
  right: 0;
  padding:15px 24px;
  box-shadow: 0 3px 20px rgba(0,0,0,.16);
  display: none;
}
.container-header-home #lang button.active+section{
  display: block;
}
.container-header-home #lang section a{
  color: var(--c5) !important;
  font-size: 12px;
  line-height: 30px;
  display: block;
  position: relative;
  cursor: pointer;
}
.container-header-home #lang section a:before{
  content: "";
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border:solid 1px #909090;
  right: 0;
  top: 50%;
  margin-top: -8px;
}
.container-header-home #lang section a.active:after{
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1B5689;
  right: 0;
  top: 50%;
  margin-top: -5px;
  right: 3px;
}

.container-header-home #lang section a{}

#eventBanner {
  color: var(--c7);
}

.spinner3 {
  width: 34px !important;
  height: 34px;
  margin: 0px auto;
}
.spinner3-b {
  width: 34px !important;
  height: 34px !important;
}
.spinner3-c {
  transform: translateZ(0) scale(0.34) !important;
}

.spinner4 {
  width: 30px !important;
  height: 30px;
  margin: -3px auto;
  float: left;
}
.spinner4-b {
  width: 30px !important;
  height: 30px !important;
}
.spinner4-c {
  transform: translateZ(0) scale(0.3) !important;
}

.spinner5 {
  width: 24px !important;
  height: 24px;
  margin: 0px auto;
}
.spinner5-b {
  width: 24px !important;
  height: 24px !important;
}
.spinner5-c {
  transform: translateZ(0) scale(0.24) !important;
}
.spinner6 {
  margin: 0;
  display: flex;
  flex: 1;
  justify-content: center;
}
.spinner6-b {
  position: relative;
}


.confirmacion {
  background-color: #FDFDFD;
}