@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ivum5p');
  src:  url('fonts/icomoon.eot?ivum5p#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ivum5p') format('truetype'),
    url('fonts/icomoon.woff?ivum5p') format('woff'),
    url('fonts/icomoon.svg?ivum5p#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
  content: "\e900";
}
.icon-verified:before {
  content: "\e901";
}
.icon-info:before {
  content: "\e902";
}
.icon-tdc:before {
  content: "\e903";
}
.icon-transf:before {
  content: "\e904";
}
.icon-pago-fallido:before {
  content: "\e905";
}
.icon-tel:before {
  content: "\e906";
}
.icon-mail:before {
  content: "\e907";
}
.icon-instagram:before {
  content: "\e908";
}
.icon-facebook:before {
  content: "\e909";
}
.icon-menos:before {
  content: "\e90a";
}
.icon-mas:before {
  content: "\e90b";
}
.icon-mas-simple:before {
  content: "\e90c";
}
.icon-menos-simple:before {
  content: "\e90d";
}
.icon-angle-d:before {
  content: "\e90e";
}
.icon-cerrar:before {
  content: "\e90f";
}
.icon-carrito:before {
  content: "\e910";
}
.icon-cerrar-simple:before {
  content: "\e911";
}
.icon-ada:before {
  content: "\e912";
}
.icon-timer:before {
  content: "\e913";
}
.icon-alert:before {
  content: "\e914";
}
.icon-term:before {
  content: "\e915";
}
.icon-share:before {
  content: "\e916";
}
.icon-calendar:before {
  content: "\e917";
}
.icon-caminar:before {
  content: "\e918";
}
.icon-volver:before {
  content: "\e919";
}
.icon-left:before {
  content: "\e91a";
}
.icon-right:before {
  content: "\e91b";
}
.icon-check:before {
  content: "\e91c";
}
.icon-check-sel:before {
  content: "\e91d";
}
.icon-back:before {
  content: "\e91e";
}
.icon-idioma:before {
  content: "\e91f";
}
