@import '../../assets/styles/variables.scss';

.testlabel{
    display: inline-block;
    width: 30px;
    height: 30px;
    z-index: 2;
    background: blue;
    text-align: center;
    line-height: 30px;
}
#ListaCategorias{
    .topCategorias{
        @media (min-width: 769px) {
        }
        @media (max-width: 768px) {
            position: relative;
            overflow: hidden;
            height: 52px;
        }
        
        .filters{
                margin-bottom: 20px;
                display: flex;
                gap: 16px;
                @media (max-width: 768px) {
                    position: absolute;
                }
            button{
                background: #fff;
                border-radius: 6px;
                font-size: 14px;
                box-shadow: 0 0 6px rgba(139,158,167,.16);
                min-width: 100px;
                text-align: center;
                line-height: 30px;
                height: 32px;
                border:solid 1px transparent;
                /*margin-right: 15px;
                margin-bottom: 20px;*/
                padding: 0 15px;
                color:$color1;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                position: relative;
                span{
                    display: flex;
                    line-height: 14px;
                    height: 14px;
                }
                &.active{
                    border: solid 1px $color6;
                    &:before{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background:var(--c1);
                        border-radius: 6px;
                        opacity: 0.1;
                        z-index: 0;
                    }
                }
            }
        }
        .search{
            max-width: 280px;
            width: 100%;
            margin-left: 50px;
            margin-bottom: 10px;
            input{
                border-color: $placeholder;
            }
        }
    }
    .categoriaResumen{
        .top{
            margin-bottom: 20px;
            
        }
    }
}

.reflect{
    transform: rotateZ(-180deg);
    display: block;
    font-size: 12px;
    line-height: 12px;
    height: 12px;
}