@media (min-width: 1361px) {
    body.f-min footer .container {
        max-width: 1140px;
    }
}
@media (min-width: 1100px) and (max-width: 1279px) {
    #wrap-pago .terminos,
    #wrap-pago .btn-pago,
    .wrap-pago .content {
        max-width: calc(100% - 370px) !important;
    }
}
@media (min-width: 1439px) {
    .wrap-pago .resumen {
        top: 0px;
    }
}
@media (min-width: 992px) {
    #wrap-ppal {
        /*margin-top: 180px;*/
        min-height: calc(100vh - 292px);
    }
}

@media (min-width: 1280px) {
    #wrap-pago .terminos,
    #wrap-pago .btn-pago,
    .wrap-pago .content,
    .wrap-pago.donation .content,
    .wrap-pago.donation .btn-pago {
        max-width: 830px;
    }
}
@media (min-width: 1280px) {
    .wrap-pago .content {
        max-width: 100%;
        max-width: calc(100% - 405px)
    }
    .wrap-pago .resumen {
        width: 100% !important;
        max-width: 375px
    }
}


@media (max-width: 1440px) {
    body {
        padding-top: 120px;
    }
    .tm-header {
        /*padding-top: 20px;*/
        height: 138px;
    }
    
    .tm-header .banner-evento .logo {
        margin-top: 12px;
        height: 70px;
    }
    .tm-header .banner-evento .logo img {
        max-width: 99px;
    }
    .tm-header .banner-evento .info-evento .datos .back {
        display: none;
    }
    /*.tm-header .banner-evento #counter,
    .tm-header .banner-evento #counter .wrap {
        height: 70px;
    }*/
    .tm-header .banner-evento .info-evento .datos .direccion,
    .tm-header .banner-evento .info-evento .datos .fecha {
        font-size: 14px;
    }
    .tm-header .banner-evento .info-evento .datos .title {
        margin-bottom: 5px;
    }
}
@media (max-width: 500px) {
    .tm-header .banner-evento #counter .color-texto-contenido {
        display: none !important;
    }
}

@media (max-width: 1200px) {
    #mapa-boletos-wrap .mapa .container-map {
        /* height: calc(100vh - 210px); */
    }

    body#evento #main .wrap-evento .banner-evento figure {
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    body#evento #main .wrap-evento .banner-evento .share-precio {
        width: 345px;
    }
    body#evento .bg-top {
        height: 480px;
    }

    body#evento #main .wrap-evento .calendar-book .button-book {
        max-width: 290px;
    }
    body#evento #main .wrap-evento .map {
        max-width: 305px;
    }
    body#evento #main .wrap-evento .map {
        padding-left: 0;
    }
    header.fixed .banner-evento .info-evento .datos .fecha,
    header.fixed .banner-evento #counter.show .wrap p {
        color: #fff;
    }

    #wrap-pago-realizado
        .wrap-orden
        .datos-o
        > div:last-child
        .d-item
        .news-letter
        .wrap {
        margin: 0 -5px;
    }
    #wrap-pago-realizado
        .wrap-orden
        .datos-o
        > div:last-child
        .d-item
        .news-letter
        .wrap
        div {
        font-size: 12px;
        padding: 0 5px;
    }
    #wrap-pago-realizado .wrap-orden .datos-o > div:last-child .d-item {
        height: 255px;
        min-height: 255px;
        padding-top: 35px;
    }
    #wrap-pago-realizado .valores article .wrap.estacionamiento .sitios p span {
        display: block;
    }

    #wrap-pago .info-pago .metodos article {
        width: 25%;
    }
    .content {
        margin-bottom: 65px;
    }
}

@media (max-width: 768px) {
    body#evento #main .wrap-evento .calendar-book .button-book,
    body#evento #main .wrap-evento .calendar-book .button-wait,
    body#evento #main .wrap-evento .calendar-book .btn-gratis {
        max-width: 465px !important;
        margin-right: 13px;
    }
}

@media (max-width: 992px) {
    #tabla-boletos tbody tr td .ctrl-cant label {
        font-size: 20px;
    }
    #tabla-boletos tbody tr td .ctrl-cant button {
        height: 35px;
        width: 35px;
    }
    #wrap-pago .info-pago .metodos article {
        width: 50%;
        margin-bottom: 30px;
    }

    #wrap-pago .info-pago .metodos article .wrap {
        margin-bottom: 10px;
    }
    #wrap-pago-realizado .wrap-orden .datos-o > div,
    #wrap-pago-realizado .valores article {
        width: 100%;
    }
    .tooltip-term {
        width: 310px;
    }
    .tooltip-term i {
        font-size: 25px;
    }
    .tooltip-term span {
        font-size: 14px;
    }
    #wrap-pago-realizado .wrap-mapa iframe {
        height: 160px !important;
    }
    #wrap-pago-realizado .wrap-orden .datos-o div .d-item {
        padding-top: 20px;
    }
    #wrap-pago-realizado .valores {
        margin-top: 35px;
    }
    #wrap-pago-realizado .valores article .wrap.estacionamiento .sitios p span {
        display: inline-block;
    }

    body#evento #main .wrap-evento .banner-evento figure {
        width: 100%;
    }
    body#evento #main .wrap-evento .banner-evento figure img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        object-position: center;
    }
    body#evento .bg-top {
        height: 482px;
    }
    body#evento #main .wrap-evento .banner-evento {
        padding-bottom: 80px;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento {
        margin-top: 30px;
        position: relative;
        padding: 0 15px;
        width: 100%;
        width: calc(100% - 15px);
        max-width: 100%;
        float: left;
    }
    body#evento #main .wrap-evento .banner-evento .share-precio {
        width: 100%;
        bottom: 20px;
        max-width: 100%;
        padding: 0 15px;
    }

    body#evento #main .wrap-evento .banner-evento .share-precio i {
        margin-top: 17px;
    }
    body#evento #main .wrap-evento .descripcion {
        max-width: 100%;
        width: 100%;
    }
    body#evento #main .wrap-evento .map {
        max-width: 100%;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 30px;
    }
    body#evento #main .wrap-evento .descripcion .video iframe {
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
    }
    body#evento #main .wrap-evento .calendar-book .button-book,
    .calendar-fix .wrap button {
        max-width: 180px;
        /* height: 60px !important; */
    }
    footer .info .dir {
        font-size: 12px;
    }
    footer .info .redes {
        margin-top: 5px;
    }
    footer .dev {
        display: none;
    }
    body.f-min footer .dev {
        display: block;
    }
    .calendar-fix .calendar {
        max-width: 450px;
    }

    #wrap-pago .terminos,
    #wrap-pago .btn-pago,
    .wrap-pago .content {
        max-width: 100%;
    }
    
    .modal-dialog {
        width: 100%;
        max-width: 780px;
    }
    /* body#evento #main .wrap-evento .calendar-book .calendar {
    max-width: 365px;
  } */
    /* body#evento #main .wrap-evento .calendar-book .button-book,
  body#evento #main .wrap-evento .calendar-book .button-wait,
  body#evento #main .wrap-evento .calendar-book .btn-gratis {
    max-width: 300px;
  } */
    #modalWait .modal-content .content > .row .bg-banner {
        display: none;
    }
   
    .tm-header{
        height: auto;
        top: -74px;
        width: calc(100% - 100px);
        left: 40px;
        pointer-events: none;
    }
    .tm-header .banner-evento{
        height: auto;
        background: transparent;
        box-shadow:none;
    }
    .tm-header .banner-evento .info-evento .datos{
        padding-left: 0px; 
        padding-top: 0px;
        float: none;
    }
    .datos .color-titulos .flex-grow-1 >div{
        width: 100%;
    }
}
@media (max-width:600px) {
    .tm-header .banner-evento .info-evento .datos .fecha{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
@media (max-width:521px) and (max-width:600px) {
    body#evento #main .wrap-evento .banner-evento figure img {
        height: 410px !important;
    }
}

@media (max-width: 1280px) {
    #mapa-boletos-wrap .wrap-boletos {
        position: fixed !important;
        top: auto !important;
        bottom: -100%;
        z-index: 5;
        background: white;
        border-radius: 5px 5px 0 0;
        transition: ease all 0.6s;
        left: 0px;
        box-shadow: 0px 0px 5px #ccc;
        width: 100%;
    }

    #mapa-boletos-wrap .wrap-boletos.show {
        bottom: 0px;
    }
    #mapa-boletos-wrap .wrap-boletos .boletos {
        border: none;
    }
    #mapa-boletos-wrap .wrap-boletos .boletos .title {
        display: none;
    }
    #mapa-boletos-wrap .wrap-boletos .boletos .content {
        min-height: 60px;
    }
    #footer-m {
        display: block !important;
        height: 60px;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        border-top: 1px solid rgb(214, 214, 214);
        background-color: white !important;
        color: var(--c4) !important;
    }
    #footer-m .car {
        height: 100%;
        display: inline-flex;
        align-items: center;
        gap: 10px;
    }
    #footer-m .car > span {
        display: block;
        position: relative;
        display: inline-block;
    }
    #footer-m .car > span span {
        /*position: absolute;*/
        color: rgb(255, 255, 255);
        border-radius: 100%;
        background: var(--c1);
        width: 18px;
        height: 18px;
        border: none;
        top: -9px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -9px;
        font-size: 14px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: bold;
    }
    #footer-m .car > p {
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        vertical-align: top;
        line-height: 16px;
    }
    #footer-m .car span i {
        font-size: 25px;
    }
    #footer-m a {
        display: block !important;
        float: right;
        width: 110px;
        line-height: 35px;
        margin-top: 7px;
    }

    #footer-m button {
        display: block !important;
        float: right;
        width: 110px;
        line-height: 45px;
        margin-top: 7px;
        height: 45px;
    }
}
@media (max-width: 1200px) {
    #wrap-pago .terminos,
    #wrap-pago .btn-pago,
    .wrap-pago .content,
    .wrap-pago.donation .content,
    .wrap-pago.donation .btn-pago {
        max-width: 650px;
    }
}
@media (max-width: 1100px) {
    #wrap-pago .info-pago,
    #wrap-pago .terminos,
    #wrap-pago .btn-pago,
    .wrap-pago .content {
        max-width: 100%;
        margin-bottom: 0;
    }
    header.fixed .banner-evento,
    header .banner-evento {
        padding-left: 0px;
    }
    header .banner-evento .img-evento {
        display: none;
    }
    #tabla-boletos tbody tr td:nth-child(3),
    #tabla-boletos thead tr th:nth-child(3) {
        width: 300px;
    }

    #thead-fee {
        display: none;
    }

    footer .info {
        max-width: 250px;
        margin-top: 30px;
    }
    footer .info .dir,
    footer .info .redes {
        float: none;
        display: inline-block;
        margin-left: 0;
    }
    footer .info .redes {
        margin-top: 20px;
    }
    #mapa-boletos-wrap {
        padding-right: 0;
    }
    .wrap-pago .resumen,
    #wrap-pago .info-pago .info-met-mobile {
        margin-top: 0px !important;
    }
    .custom-checkbox-mobile {
        margin-top: 0px !important;
    }
    #wrap-pago .info-met#tdc {
        margin-top: 0;
    }
    .wrap-pago {
        padding-right: 0;
    }
    #wrap-pago-realizado .valores article .wrap {
        padding: 10px 30px 10px 100px;
    }
    #wrap-pago-realizado .redes-f article {
        width: 100%;
        margin-bottom: 35px;
    }
    #wrap-pago-realizado > .title {
        font-size: 22px;
    }
    #wrap-pago-realizado .send img {
        margin-bottom: 0px;
    }

    #wrap-pago-realizado .wrap-orden .datos-o div .d-item > div .title,
    #wrap-pago-realizado .valores article .wrap .content .title,
    #wrap-pago-realizado .wrap-mapa .s-title,
    #wrap-pago-realizado .redes-f article .title,
    #wrap-pago-realizado .nota .title {
        font-size: 18px;
    }

    #wrap-pago-realizado .send p,
    #wrap-pago-realizado .wrap-orden .s-title,
    #wrap-pago-realizado .valores article .wrap .content p {
        font-size: 16px;
    }
    body:not(.f-perm) #main #wrap-ppal{
        padding-bottom: 0px;
        margin-top: -50px;
    }
    #main footer:not(.f-perm) {
        display: none;
    }
    .btn-get-tk:not(.btn-pay-gratis),
    .btn-ticket-mapa:not(.btn-pay-gratis) {
        display: none;
    }

    #wrap-pago .info-pago .metodos article .wrap:before {
        position: absolute;
        margin-top: 120px;
        margin-left: 11.5%;
        transform: translateX(-50%);
        border: solid 1px #d6d6d6;
        border-radius: 50%;
        content: "";
        display: block;
        width: 20px;
        height: 20px;
    }

    #wrap-pago .info-pago .metodos article .wrap.selected:after {
        position: absolute;
        margin-top: 44px;
        margin-left: 11.5%;
        transform: translateX(-50%);
        background: var(--c1);
        border-radius: 50%;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
    }

    .icon-share-2 {
        cursor: pointer;
        margin-top: 15px;
        display: inline-block;
    }

    .show-seats {
        cursor: pointer;
    }
    #mapa-boletos-wrap .mapa {
        border: none;
        padding: 0px 0;
    }
    #mapa-boletos-wrap {
        margin: 0px;
    }
    #tabla-boletos thead tr th:nth-child(2) {
        padding-left: 0px;
        width: 300px;
    }
    #tabla-boletos tbody tr td:nth-child(2) {
        padding-left: 0px;
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    #tabla-boletos tbody tr td:nth-child(1) {
        padding-left: 10px;
        width: 350px;
    }
    #tabla-boletos tbody tr td:last-child {
        height: 82px;
    }
    #mapa-boletos-wrap {
        margin: 0px;
    }
    #mapa-boletos-wrap
        .wrap-boletos
        .boletos
        .content
        .title-t
        span:nth-child(1),
    #mapa-boletos-wrap
        .wrap-boletos
        .boletos
        .content
        .item
        > span:nth-child(1) {
        width: 70%;
    }

    #wrap-pago .btn-pago span {
        display: inline-block;
    }

    /* body#evento #main .wrap-evento .calendar-book .calendar {
    max-width: 460px;
  } */
    /* body#evento #main .wrap-evento .calendar-book .button-book {
    max-width: 100%;
  } */

    .calendar-fix .calendar {
        /* max-width: 400px; */
        width: auto;
        position: relative;
        border-radius: 0px;
        padding: 15px 15px;
        top: 0px;
        margin: 0 -15px;
    }
    .calendar-fix .wrap .title {
        line-height: 16px;
        margin-top: 0px;
        float: none;
        padding: 15px 0;
        border-bottom: solid 1px rgb(240, 240, 240);
        text-transform: uppercase;
    }
    .calendar-fix {
        height: 100px;
    }
    .calendar-fix .calendar i.icon-angle-d {
        left: auto;
        right: 15px;
        top: 17px;
        pointer-events: none;
    }
    .calendar-fix .calendar i.icon-calendar {
        right: auto;
        left: 15px;
        top: 17px;
        pointer-events: none;
    }
    .calendar-fix .calendar .item.sel {
        padding-left: 30px;
        text-transform: uppercase;
    }
    #modalGratis .wrap-gratis {
        padding-right: 0;
    }
    #modalGratis .wrap-gratis .resumen {
        position: relative;
        width: 100%;
    }
    #modalGratis .wrap-gratis .resumen > figure {
        display: none;
    }
    #modalGratis .wrap-gratis .wrap-info {
        height: auto;
    }
    /* body#evento #main .wrap-evento .calendar-book .calendar,
  body#evento #main .wrap-evento .calendar-book .button-book,
  body#evento #main .wrap-evento .calendar-book .btn-gratis {
    width: 100%;
    left: 30px;
  } */
    #modalGratis .wrap-gratis .resumen .wrap-resumen {
        margin-bottom: 30px;
    }
    #modalGratis .wrap-gratis .resumen #registrarG {
        max-width: 90%;
        max-width: calc(100% - 40px);
    }
    #modalGratis {
        padding: 0 !important;
        background: rgb(255, 255, 255);
    }
    #modalGratis .modal-content {
        border: none !important;
        box-shadow: none !important;
    }
    
    .resumen-merch {
        display: none;
    }
    .footer-m-merch {
        background: rgb(255, 255, 255) !important;
        border-top: solid 1px rgb(190, 190, 190) !important;
    }

    header.fixed .banner-evento #counter {
        margin-top: 12px;
    }

    body.h-fixed {
        padding-top: 120px;
    }

    header.fixed {
        height: 70px;
    }
    header.fixed .banner-evento,
    header.fixed .banner-evento {
        height: 70px;
        margin-top: 0px;
        background: none;
        padding-left: 0;
    }
    header.fixed .banner-evento .info-evento {
        position: relative;
        padding-right: 120px;
    }
    header.fixed .banner-evento #counter {
        height: 56px;
        margin-top: 7px;
        padding: 0 0px;
        position: absolute;
        right: 0;
    }
    header.fixed .banner-evento #counter .wrap {
        height: 56px;
    }
    header.fixed .banner-evento .info-evento .datos {
        padding-left: 0px;
        padding-top: 10px;
        width: 100%;
    }
    header.fixed .banner-evento .info-evento .datos .title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    header.fixed .banner-evento .info-evento .datos .fecha {
        font-size: 14px;
    }
    header.fixed .banner-evento #counter {
        border-left: none;
    }
    header.fixed .banner-evento #counter span {
        display: none;
    }
    header.fixed .banner-evento .logo {
        display: none;
    }
    header.fixed .banner-evento .img-evento {
        display: none;
    }
    .wrap-pago .resumen {
      position: relative;
      width: 100%;
      max-width: 100%;
      top: 0;
      z-index: 1;
  }
}

@media (max-width: 992px) {
    .calendar-fix .wrap button,
    .calendar-fix .wrap button,
    .calendar-fix .wrap button,
    .calendar-fix .wrap .precio {
        display: none !important;
    }
    .entradas-precios .button-book {
    }
    .logo-ext {
        display: none !important;
    }
}
@media (max-width: 640px) {
    body:not(.f-perm) footer {
        display: none;
    }
    body#evento #main .wrap-evento {
        margin-bottom: 60px;
    }
    body#evento #main .wrap-evento .banner-evento figure img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: center;
    }
    body:not(.f-perm) #main #wrap-ppal{
        padding-bottom: 0px;
        margin-top: -50px;
    }
    .tm-header .banner-evento .img-evento{
        display: none;
    }
}
@media (max-width: 576px) {
    #main #wrap-ppal.pageMapa .container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .wrap-pago .content {
        margin-top: 0px !important;
        margin-bottom: 0px;
    }
    .wrap-pago .resumen {
        margin-bottom: 0;
    }
    #mapa-boletos-wrap {
        margin: 0px 0;
    }
    .body-mapa {
        background: rgb(250, 250, 250);
    }
    body#evento #main .wrap-evento .banner-evento,
    body#evento #main .wrap-evento .banner-donacion {
        border-radius: 0;
    }
    header .banner-evento .info-evento .datos .direccion {
        display: none;
    }
    body {
        padding-top: 70px;
        padding-bottom: 50px;
    }
    /*header,
    header.fixed {
        height: 70px;
    }*/
    header.fixed .banner-evento,
    header .banner-evento {
        height: 70px;
        margin-top: 0px;
        background: none;
    }
    header .banner-evento .info-evento {
        position: relative;
        /*padding-right: 120px;*/
    }
    header .banner-evento #counter {
        height: 56px;
        margin-top: 7px;
        padding: 0 0px;
        position: absolute;
        right: 0;
    }
    header .banner-evento #counter .wrap {
        height: 56px;
        padding-left: 10px;
    }
    header .banner-evento #counter .wrap span {
        display: none;
    }
    header .banner-evento #counter {
        border-left: none;
    }
    header .banner-evento .info-evento .datos {
        padding: 0px;
        width: 100%;
        justify-content: flex-start;
        display: flex;
        align-items: center;
    }
    header .banner-evento .info-evento .datos .title,
    header .banner-evento .info-evento .datos .fecha {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    header .banner-evento .info-evento .datos .fecha {
        font-size: 14px;
    }
    header .banner-evento .logo {
        display: none;
    }
    .container-map-pres #tabla-boletos-wrap {
        padding: 0 15px;
    }

    #tabla-boletos-wrap {
        margin: 50px 0;
        overflow-x: auto;
    }
    #tabla-boletos thead tr th:nth-child(4) span {
        display: none;
    }
    #tabla-boletos thead tr th:nth-child(4) i {
        display: block;
        font-style: normal;
    }

    #tabla-boletos tbody tr td {
        padding: 20px 5px;
        font-size: 14px;
        border-bottom: solid 1px rgb(214, 214, 214);
    }
    #tabla-boletos tbody tr:first-child td {
        padding-top: 20px;
    }
    #tabla-boletos tbody tr:last-child td {
        padding-bottom: 20px;
    }
    #tabla-boletos thead tr th {
        font-size: 14px;
    }
    #tabla-boletos tbody tr td:nth-child(1),
    #tabla-boletos thead tr th:nth-child(1) {
        padding-left: 10px;
        width: 250px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    #tabla-boletos thead tr th:nth-child(3),
    #tabla-boletos tbody tr td:nth-child(4) {
        width: 140px !important;
    }

    #main #wrap-ppal.pageMapa {
        margin-top: -40px !important;
        height: calc(100vh - 146px);
        padding-bottom: 0 !important;
        display: inline-flex;
        width: 100%;
    }

    #wrap-pago .datos-cli .row .col {
        width: 100%;
    }
    #wrap-pago .btn-pago,
    #wrap-pagoGratis .btn-pago-gratis {
        position: fixed;
        bottom: 0;
        border-radius: 0;
        left: 0;
        margin: 0 auto;
        z-index: 99999;
        height: 60px;
    }
    .wrap-pago {
        margin-bottom: 30px;
    }
    body.f-perm footer .info {
        max-width: 100%;
        width: 100%;
        margin-top: 20px;
    }
    body.f-perm footer .info .dir,
    body.f-perm footer .info .redes {
        display: block;
        text-align: center;
        max-width: 100%;
        width: 100%;
    }
    body.f-perm footer .info .redes {
        margin-top: 10px;
    }
    body.f-perm footer .info .redes a {
        line-height: 35px;
    }
    body.f-perm footer .dev {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    body.f-perm.f-min footer .dev {
        margin-top: 0;
    }
    body.f-min footer .dev img {
        width: 150px;
        margin-top: 3px;
        float: right;
    }
    body.f-perm footer .dev p {
        text-align: right;
        width: 100%;
        margin-top: 12px;
    }
    #mapa-boletos-wrap .mapa .container-map {
        /* height: calc(100vh - 240px); */
        width: 100%;
    }
    .confirmacion .title-h h1 {
        font-size: 20px;
        padding-right: 0;
    }
    #wrap-pago-realizado .wrap-orden .datos-o div .d-item > div .title,
    #wrap-pago-realizado .valores article .wrap .content .title,
    #wrap-pago-realizado .wrap-mapa .s-title,
    #wrap-pago-realizado .redes-f article .title,
    #wrap-pago-realizado .nota .title {
        font-size: 16px;
    }
    #wrap-pago-realizado .send p,
    #wrap-pago-realizado .wrap-orden .s-title,
    #wrap-pago-realizado .valores article .wrap .content p {
    }
    #wrap-pago-realizado .valores article .wrap,
    #wrap-pago-realizado .valores article .wrap.estacionamiento {
        padding: 10px 10px 10px 80px;
    }
    #wrap-pago-realizado .valores article .wrap img {
        width: 60px;
        left: 10px;
    }
    #wrap-pago-realizado .valores article .wrap.calendar img {
        width: 40px;
        margin-left: 10px;
    }
    #wrap-pago-realizado .valores article .wrap.donacion p br {
        display: block;
    }
    #modalTerminos .modal-content {
        padding: 20px 0px;
    }
    #modalTerminos .modal-content .wrap-terms .text {
        border: none;
    }
    #modalTerminos .modal-content .cerrar {
        top: 10px;
        right: 10px;
    }

    .conten-share .clear-fl .cerrar {
        width: 100%;
        height: 30px;
    }

    #modalMoreDon .modal-content .conten-don {
        padding: 0 0px 20px 0px;
        position: relative;
    }
    #modalMoreDon .modal-content .wrap-terms .text {
        border: none;
        padding: 0;
    }

    #wrap-pago-realizado .send img {
        display: block;
        margin: 0 auto 20px;
    }
    .confirmacion header img {
        display: none;
    }
    
    .mainE {
        padding-top: 0 !important;
    }
    .mainE .container {
        padding-left: 0px;
        padding-right: 0px;
    }
    body#evento #main .wrap-evento {
        margin: 0px auto;
        border-radius: 0px;
        padding-bottom: 70px;
    }
    body#evento #main .wrap-evento .calendar-book .button-book,
    body#evento #main .wrap-evento .calendar-book .button-wait {
        position: fixed;
        bottom: 0;
        border-radius: 0;
        width: 100%;
        max-width: 100% !important;
        left: 0;
        z-index: 999;
        display: none;
        line-height: 60px;
        height: 60px;
    }
    body#evento #main .wrap-evento .calendar-book .button-wait {
        display: none;
        color: #fff !important;
        background: rgb(255, 157, 0) !important;
        border: solid 1px rgb(255, 157, 0) !important;
    }
    body#evento #main .wrap-evento .calendar-book .button-wait:hover {
        color: rgb(255, 255, 255) !important;
        background: rgb(0, 98, 196) !important;
        border: solid 1px rgb(0, 98, 196) !important;
    }
    body#evento #main .wrap-evento .calendar-book .button-book.active {
        display: block;
    }
    body#evento #main .wrap-evento .calendar-book .calendar {
        max-width: 100%;
    }
    body#evento .calendar .wrap-opciones article .fecha p,
    body#evento .calendar .wrap-opciones article .dir p {
        font-size: 14px;
        line-height: 16px;
    }
    #wrap-pago-realizado .valores article .wrap.estacionamiento .sitios p {
        font-size: 12px;
    }
    #wrap-pago-realizado .valores article .wrap.donacion .content {
        padding: 10px 20px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 4px;
    }
    #wrap-pago-realizado
        .wrap-orden
        .datos-o
        > div:last-child
        .d-item
        .news-letter
        .wrap
        div:first-child {
        margin-bottom: 15px;
    }
    #wrap-pago-realizado
        .wrap-orden
        .datos-o
        > div:last-child
        .d-item
        .news-letter
        .wrap
        div {
        width: 100%;
    }
    #wrap-pago-realizado .wrap-orden .datos-o > div:last-child .d-item {
        height: 315px;
        min-height: 315px;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento {
        margin-top: 30px;
        position: relative;
        padding: 0 15px;
        width: 100%;
        max-width: 900px;
        float: left;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r {
        right: 15px;
    }
    #wrap-pago-realizado .redes-f {
        margin: 30px -15px 0;
    }
    #wrap-pago-realizado .redes-f article {
        padding: 0 15px;
    }
    .wrap-merch .productos .itemprod .desc .colores-m {
        display: block;
        line-height: 12px;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .wrap-merch .productos .itemprod .desc .colores {
        display: none;
    }
    #modalProd .modal-content .detalleProd .info {
        width: 100%;
    }
    #modalProd .modal-content .detalleProd .images {
        width: 100%;
        margin-bottom: 30px;
    }
    #modalProd .modal-content .detalleProd .images .slider {
        padding-left: 10px;
    }
    #modalProd .modal-content .detalleProd .images .slider > div:last-child {
        display: none;
    }
    #modalProd .modal-dialog {
        padding: 0 0 !important;
    }
    #modalProd .modal-dialog .modal-content {
        border-radius: 0 !important;
        padding: 40px 0px 0px;
    }
    #modalProd .modal-content .detalleProd .info button {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0;
    }
    #modalProd .modal-content .detalleProd .images {
        padding: 0 20px;
    }
    #modalProd .modal-content .detalleProd .info {
        padding: 0 20px 60px;
    }
    .pageMapa #wrap-cont .container #mapa-boletos-wrap {
        margin-top: -50px;
    }
}

@media (max-width: 480px) {
    .pageMapa #wrap-cont .container #mapa-boletos-wrap {
        height: 77vh;
    }
    .subText {
        max-width: 140px;
        text-overflow: ellipsis;
        white-space: normal;
        overflow: hidden;
    }
    #tabla-boletos tbody tr td:nth-child(1),
    #tabla-boletos thead tr th:nth-child(1) {
        width: 200px;
    }
    #wrap-pago-realizado .valores article .wrap.estacionamiento .sitios p span {
        display: block;
    }
}

@media (max-width: 414px) {
    #tabla-boletos tbody tr td .ctrl-cant label {
        line-height: 35px;
    }
}
@media (max-width: 360px) {
    #tabla-boletos tbody tr td:nth-child(1),
    #tabla-boletos thead tr th:nth-child(1) {
        width: 170px;
    }
    body#evento #main .wrap-evento .banner-evento figure img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        object-position: center;
    }
}

@media (max-height: 800px) {
}
.notas ol{
    list-style: auto;
}
/*textos rediseño*/
@media (max-width: 1440px) {
    .como-llegar .sub-title-bold {
        margin-bottom: 10px;
    }
}
@media (max-width: 1200px) {
    .sub-title-bold {
        font-size: 18px;
    }
    /*body#evento #main .wrap-evento .descripcion p,*/
    body#evento #main .wrap-evento .map p,
    .wrap-evento .notas p {
        font-size: 15px;
        line-height: 20px;
    }
    body#evento #main .wrap-evento .precio b {
        font-size: 24px;
        line-height: 26px;
    }
    .wrap-evento .entradas-precios {
        font-size: 15px;
    }
    .wrap-evento .entradas-precios {
        min-height: 200px;
    }
}
@media (max-width: 1024px) {
}
@media (max-width: 992px) {
    .fixed-in-movil {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 99999;
        background: #fff;
        padding: 30px 15px 20px;
        border-radius: 8px 8px 0 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    .fixed-in-movil .precio {
        margin: 0 !important;
    }
    .fixed-in-movil .button-book {
        margin-top: 0px !important;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento .title {
        font-size: 18px !important;
    }
    .info-evento .label_cont{
        width: calc(100% - 90px);
        padding: 20px 0 0 !important;
    }
    body#evento #main .wrap-evento .info-evento .label {
        padding: 3px 10px;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r span {
        font-size: 18px !important;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r p {
        font-size: 20px !important;
        line-height: 20px;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r {
        top: -10px !important;
    }
    body#evento #main .wrap-evento .banner-evento .Carrusel-banner,
    body#evento #main .wrap-evento .banner-evento figure {
        overflow: visible;
    }
    body#evento
        #main
        .wrap-evento
        .banner-evento
        .Carrusel-banner
        button.share-button,
    body#evento #main .wrap-evento .banner-evento figure button.share-button {
        bottom: -120px;
        right: 15px;
        z-index: 99;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    
    body#evento #main .wrap-evento {
        padding-bottom: 220px;
    }
    body:not(.f-perm) footer {
        display: none;
    }
}
@media (max-width: 992px) {
    body#evento #main .wrap-evento .banner-evento label.favorite-button ,
    body#evento #main .wrap-evento .banner-evento button.favorite-button {
        bottom: -120px;
        right: 65px;
        z-index: 99;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
}
@media (max-width: 769px) {
    body#evento #main .wrap-evento .precio .change-currency {
        font-size: 14px !important;
    }
    body#evento #main .wrap-evento .precio b {
        font-size: 18px !important;
    }
    body#evento #main .wrap-evento,
    .wrap-evento .column-left {
        overflow: visible !important;
    }
    body#evento #main .wrap-evento .banner-evento,
    body#evento #main .wrap-evento .banner-donacion {
        margin-left: -10px;
        margin-right: -10px;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento .title {
        white-space: pre-wrap;
    }
}
/*textos rediseño*/

.header_login{
    z-index: 9999999;
    width: 100%;
    text-align: right;
    pointer-events: none;
    position: absolute;
    /* top: 16px;
    right: 143px; */
  }
  .he_fixed{
    position: fixed;
  }
  .header_login button{
    pointer-events: all;
  }
  .no_event_h{
    height: 96px;
    line-height: 82px;
    pointer-events: none;
  }
  .header_login .wrap-evento{
    max-width: 1080px;
    margin: 0 auto;
    pointer-events: none;
  }
  
  #wrap-pago .datos-cli .login_cont {
    display: flex;
    flex-direction: column; 
    gap: 10px;
    max-width: 100%;
  }
  #wrap-pago .datos-cli .login_cont b{
    display: flex;
    font-weight: 700;
  }
  #wrap-pago .datos-cli .login_cont .btn_login_false{
    /*color: rgb(9, 203, 204);*/
    display: block;
    font-weight: 600;
    font-size: 14px;
    background: var(--c1);
    color: white;
    font-family: 'Raleway';
    text-align: center;
    margin: 0;
    border-radius: 6px;
    line-height: 40px;
    cursor:pointer;
  }
  
  #wrap-pago .datos-cli .login_cont span{
    font-weight: 400;
    font-size: 14px;
  }
  
  #wrap-pago .datos-cli .login_cont >div{
    display: flex;
    gap: 30px;
  }
  
  #wrap-pago .datos-cli .login_cont >div> div{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: space-between;
  }
  @media (max-width: 769px) {
    #wrap-pago .datos-cli .login_cont{
        gap: 0;
    }
    #wrap-pago .datos-cli .login_cont >div{
        padding: 20px 16px;
        flex-direction: column-reverse;
        width: 100%;
        gap: 20px;
    }

    #wrap-pago .datos-cli .login_cont >div> div{
        width: 100%;
        gap: 16px;
    }
    .login_cont2{
        padding: 16px 0 32px;
    }
}