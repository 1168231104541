@import "~bootstrap/scss/bootstrap.scss";
@import "../../assets/icons/style.css";
@import "../../assets/styles/variables.scss";

//Commons
body {
    background: #fdfdfd;
    font-size: 14px;
    color: $color1;
}
a,
a:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
#aplicationShop {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: $color1;
    background: #f1f2f4;
    padding-top: 80px;
}

#middleLayout {
    width: 100%;
    flex-grow: 1;
}
.paddingY70 {
    padding: 70px 0;
}

.f-hr {
    font-weight: 400;
}
.f-hm {
    font-weight: 600;
}
.f-hb {
    font-weight: bold;
}

.btnGen {
    background: $color6;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    height: 40px;
    border-radius: 8px;
    padding: 0 20px;
    color: #fff !important;
    transition: ease-in-out all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    min-width: 120px;
    &::before {
        content: "";
        display: block;
        position: absolute;
        background: #000;
        opacity: 0;
        transition: ease-in-out all 0.2s;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    &:hover::before {
        opacity: 0.1;
    }

    span {
        display: flex;
        position: relative;
        line-height: 14px;
    }
}

.btnOutline {
    height: 40px;
    line-height: 38px;
    color: $color6;
    font-size: 14px;
    text-align: center;
    border: solid 1px $color6;
    border-radius: 8px;
    background: #fff;
    margin: 0 auto;
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    span {
        display: flex;
        line-height: 14px;
    }
    &:hover {
        color: $color6;
        border: solid 1px $color6 !important;
    }
}
button,
.btn:focus,
.btn.focus {
    outline: none;
    box-shadow: none;
}

.back {
    cursor: pointer;
}

.textTitle {
    font-size: 24px;
}
.textSubTitle {
    font-size: 18px;
}
.textMedium {
    font-size: 14px;
    color: $color1;
}
.textRegular {
    font-size: 14px;
    color: $color5;
}
.textSec {
    font-size: 12px;
}
//end commons

//boton wtsp
._1bpcM {
    background-color: #4dca5b;
    position: fixed;
    bottom: 60px;
    svg {
        fill: #fff;
    }
    z-index: 99;
}
._2iWL7 {
    bottom: 130px;
    position: fixed;
    z-index: 99;
}
.spinner-custom {
    color: $color6 !important;
}
//Modify INTL
.iti {
    display: block;
}
.iti__flag,
.iti__arrow {
    display: none;
}
.iti--separate-dial-code .iti__selected-dial-code {
    margin-left: 0;
}
.iti--separate-dial-code .iti__selected-flag {
    background: none;
    padding: 0 15px;
}
//Modify INTL

//ANIMACIONES
.scaleOff {
    transform: scale(0, 0);
    transition: ease-in-out 0.3s transform;
}
.scaleOn {
    transform: scale(1, 1);
}
//ANIMACIONES
.sk-main{
    width: 100%;
}
.generalSkeleton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    // background: #fff;
    .sk-header {
        height: 80px;
        align-items: center;
        display: flex;
        border-bottom: solid 1px $placeholder;
        .sk-logo,
        .sk-car {
            height: 40px;
            width: 100px;
        }
    }
    .sk-banner {
        height: 400px;
        margin-bottom: 50px;
        border-radius: 0;
    }
    .sk-categorias {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: flex-start;
        & > span {
            @media (min-width: 769px) {
                width: calc(33% - 16px);
            }
            @media (max-width: 768px) {
                width: calc(50% - 16px);
            }
            .sk-cat {
                height: 180px;
            }
        }
    }
    .sk-footer {
        height: 60px;
        border-radius: 0;
    }
}
.sk-categorias2 {
    width: 100%;
    span {
       width: 100%;
        .sk-cat {
            height: 400px;
            margin-bottom: 40px;
        }
    }
}
#wrap-tienda {
    @media (min-width: 769px) {
        margin-top: 30px;
    }
    @media (max-width: 768px) {
        margin-top: -20px;
    }
    .wrap-grid {
        display: grid;
        grid-template-columns: auto 375px;
        gap: 30px;
        @media (max-width: 1280px) {
            
            grid-template-rows: auto auto;
            & > div:nth-child(1) {
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 2;
            }
            & > div:nth-child(2) {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
            }
        }
        @media (max-width:480px) {
            display: inline-flex !important;
            justify-content: center;
            align-items: center;
            gap: 0;
            width: 100%;
        }
    }
}

.notaProd {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    svg {
        fill: var(--c1);
        width: 60px;
        margin-right: 5px;
    }
    span {
        color: var(--c5);
    }
}

.categoriaResumen {
    width: 100%;
    h2 {
        color: var(--c7);
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        margin: 0;
    }
}
.gridProductos {
    @media (min-width: 1400px) {
        .col-lg-6 {
            width: 33.333333%;
            max-width: 33.3333333%;
        }
    }
    @media (min-width: 1980px) {
        .col-lg-6 {
            width: 25%;
            max-width: 25%;
        }
    }
    @media (max-width: 800px) {
        .d-flex{
            padding: 0 8px !important;
        }
    }
}

.resumen-tienda {
    position: sticky !important;
    top: 25px;
    @media (max-width: 1280px) {
        display: none !important;
    }
    .title-g {
        font-weight: 700;
        font-family: var(--font);
    }
    .wrap-resumen {
        border-radius: 8px;
        background: #fff;
        border: 1px solid #e4e8ed;
        .tickets {
            padding: 1px 26px 0;
            .title {
                display: block;
                margin-bottom: 20px;
                font-size: 12px;
                color: #b4b4b4;
                span {
                    display: block;
                    float: left;
                    width: 20%;
                    text-align: right;
                    padding: 0 5px;
                    &:first-child {
                        width: 40%;
                        text-align: left;
                    }
                    &:last-child {
                        text-align: right;
                        width: 40%;
                    }
                }
            }
            &.show {
                article {
                    border-bottom: 1px solid #ebebeb;
                    .ticket {
                        display: block;
                        line-height: 17px;
                        font-weight: 400;
                        margin-bottom: 15px;
                        position: relative;
                        color: #000;
                    }
                }
            }
        }
        .tickets.show a {
            display: block;
            margin: 25px auto 15px;
            p:first-child {
                width: 50%;
                text-align: left;
                font-weight: 700;
            }
        }
    }
    #total {
        padding: 20px 25px;
        bottom: 0;
        border-radius: 0 0 8px 8px;
        width: 100%;
        height: auto;
        p {
            font-size: 18px;
            color: #fff;
            font-weight: 700;
            line-height: 20px;
            span {
                float: right;
            }
        }
    }
    @media (max-width: 1280px) {
        margin-bottom: 50px;
        .btn-get-tk,
        .btn-ticket-mapa {
            display: block !important;
        }
    }
}

.resume-tienda-movil {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 9999999;
    padding: 7px 0px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    @media (min-width: 1280px) {
        display: none;
    }
    .wrap-resumen {
        padding: 0px 40px;
        max-height: 200px;
        overflow-y: auto !important;
        height: 0;
        transition: ease-in-out all .2s;
        &.active{
            height: 200px;
        }
        .car{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .wrapItem{
            .item{
                position: relative;
                .price {
                    i{
                        right: -25px !important;
                    }
                }
            }
        }
        #detailCarTop section{
            overflow:visible;
        }
    }
    .footer {
        
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 10;
        @media (min-width: 577px) {
            padding: 0px 40px;
        }
        @media (max-width: 576px) {
            padding: 0px 10px;
        }
        .button-car {
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
            line-height: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
            img{
                display: block;
                margin-right: 20px;
            }
        }
        .btn-skin{
            max-width: 114px
        }
    }
}
.wrap-pago{
    .resumen{
        @media (max-width: 995px) {
            margin-bottom: 0px !important;
        }
    }
}
.resume-pay-mobile{
    @media (min-width: 996px){
        .title-g{
            svg{
                display: none;
            }
        }
    }
    @media (max-width: 995px) {
        border-bottom: 1px solid #e4e8ed;
        .title-g{
            margin: 0 !important;
            span{
                padding: 20px 0;
                justify-content: space-between;
                width: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}

@import "./inputs.scss";
@import "./inputCode.scss";
@import "./header.scss";
@import "./footer.scss";
@import "./banner.scss";
@import "./home.scss";
@import "./categorias.scss";
@import "./productos.scss";
@import "./confirmacion.scss";
@import "./pago.scss";
@import "./perfilPedidos.scss";
@import "./auth.scss";
