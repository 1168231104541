.container-custom-tm{
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    padding: 0 10px;
    display: block;
    height: 100%;
}
.ajust_flex{
    display: inline-flex !important;
    justify-content: space-between;
}
.ajust_flex2{
    display: flex !important;
    gap: 30px;
}
@media (max-width: 800px) {
    .ajust_flex2{
        flex-direction: column;
    }
}
@media (max-width: 1100px) {
    .responsive_inverse{
        display: flex;
        flex-direction: column-reverse;
    }
}
.css-1eg0sfo-skeletonStyles-Skeleton{
    display: flex !important;
}
/*
@media (min-width: 576px) {
    .container-custom-tm{
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container-custom-tm{
        padding: 0 0px;
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container-custom-tm{
        max-width: 960px;
    }
}

@media (min-width: 1100px) {
    .container-custom-tm{
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container-custom-tm{
        padding: 0 40px;
        max-width: 100%;
    }
}*/

@media (min-width: 1480px ) {
    .container-custom-tm{
        /* max-width: 1400px; */
        padding: 0 60px;
    }
}
@media (min-width: 1620px ) {
    .container-custom-tm{
        /* max-width: 1536px; */
        padding: 0 80px;
    }
}