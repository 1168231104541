/*#wrap-ppal {
	margin-top: 150px;
}*/

@media (max-width: 576px){
	#wrap-ppal {
		margin-top: 0px;
	}
}
#wrap-pago .info-pago .metodos article .wrap.selected  {
	/*border: none !important;*/
}
#wrap-pago .info-pago .metodos article .wrap {
}
