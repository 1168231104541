* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    height: 100%;
}
body {
    height: 100%;
    position: relative;
    overflow-x: hidden;
    font-family: var(--font);
    min-width: 340px !important;
    padding-top: 160px;
}
#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: #fafafa;
    justify-content: space-between;
}
a {
    text-decoration: none;
}
a:hover,
a:focus {
    text-decoration: none;
}
p {
    margin-bottom: 0;
}
.clear-fl:before,
.clear-fl:after {
    content: "";
    display: block;
    clear: both;
    overflow: hidden;
}

.f-left {
    float: left;
}
.f-right {
    float: right;
}

.title-banner-tabla {
    font-size: 16px;

    font-weight: bold;
}

header.fixed .banner-evento #counter .wrap {
    padding: 0 10px;
}
.text-14-153 {
    font-size: 14px;
    font-weight: 300;
}
.text-tabla {
    font-size: 16px;
    color: var(--c4) !important;
}
.sub-title-bold {
    font-weight: bold;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
}
.title-bold-18 {
    font-weight: bold;

    font-size: 18px;
}
/****CLASES TEXT****/
/****Layout Heigh****/
.main-mapa {
    min-height: 0;
  background: #FDFDFD !important;
  padding-top: 0 !important;
}
.custom-checkbox {
    /* margin-top: 50px; */
}
.logo-home {
    max-width: 200px;
    max-height: 60px;
}
.logo-home img {
    /* width: 100%; */
    max-width: 200px;
    max-height: 60px;
}

.custom-checkbox label a {
    cursor: pointer;
}
#main {
    min-height: 100%;
    position: relative;
}
#main #wrap-ppal {
    position: relative;
}
body footer {
    padding: 30px 0;
    width: 100%;
    position: relative;
    margin-top: auto;
}

body footer figure{
    position: relative;
}
body footer figure span{
    font-size: 10px;
    color: #FFF;
    position: absolute;
    right: 0;
    top: 100%;
}
/****Layout Heigh****/

/****HEADER****/
.tm-header {
    padding-top: 0px;
    /*padding-top: 12px;
    height: 170px;*/
    height: 138px;
    width: 100%;
    top: 0;
    position: relative;
    overflow: hidden;
}
.tm-header .banner-evento {
    padding-left: 225px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    background: #fff;
    height: 120px;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.1);
    /*margin-top: 17px;*/
}
.tm-header.fixed {
    height: 120px;
}

.tm-header.fixed .banner-evento {
    height: 94px;
    margin-top: 13px;
    padding-left: 180px;
}
.tm-header.fixed .banner-evento .logo {
    margin-top: 12px;
    height: 70px;
}
.tm-header.fixed .banner-evento .logo img {
    max-width: 67px;
}
.tm-header.fixed .banner-evento .img-evento {
    width: 180px;
    height: 100px;
}
.tm-header.fixed .banner-evento .info-evento .datos .back {
    display: none;
}
.tm-header.fixed .banner-evento .info-evento .datos .title {
    margin-bottom: 5px;
    margin-top: 5px;
}
.tm-header.fixed .banner-evento .info-evento .datos .direccion,
.tm-header.fixed .banner-evento .info-evento .datos .fecha {
    font-size: 14px;
}

.tm-header .banner-evento .img-evento {
    position: absolute;
    width: 225px;
    height: 125px;
    border-radius: 3px 0 0 3px;
    overflow: hidden;
    left: -1px;
    top:-3px;
}
.tm-header .banner-evento .img-evento img {
    position: absolute;
    width: 100%;
    padding: 0;
    height: 100%;
    margin-top: 0;
}
@media (max-width: 1100px) {
    .tm-header .banner-evento .img-evento img {
        display: none;
    }
    .tm-header .banner-evento{
        padding-left: 0px !important;
    }
}
.tm-header .banner-evento .logo {
    float: right;
    padding: 0 40px;
    border-left: solid 1px rgba(244, 244, 244);
    height: 87px;
    margin-top: 19px;
}
.tm-header .banner-evento .logo img {
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 120px;
}

.tm-header .banner-evento .info-evento {
}

.tm-header .banner-evento .info-evento .datos {
    padding-left: 40px;
    padding-top: 15px;
    float: left;
}
.tm-header .banner-evento .info-evento .datos >div{
    width: calc(100% - 60px);
}
.tm-header .banner-evento .info-evento .datos .title {
    margin-bottom: 10px;
    font-family: var(--font);
}
.tm-header .banner-evento .info-evento .datos .direccion {
    margin-bottom: 5px;
    line-height: 18px;
}
.tm-header .banner-evento .info-evento .datos .fecha {
    margin-bottom: 5px;
    line-height: 18px;
}
.tm-header .banner-evento .info-evento .datos .back {
    font-size: 12px;
    line-height: 18px;
}

.header-ev {
    background: #fff;
    position: relative;
    max-height: 100px;
    padding: 18px 0;
    z-index: 2;
}
@media (min-width: 681px) {
    .header-ev {
        min-height: 61px;
    }
}
@media (max-width: 680px) {
    .header-ev {
        min-height: 80px;
    }
}
.tk_events{
    background: #E9EBED !important;
}
/*.tk_events .container-header-home{
    max-width: 1350px !important;
}*/

.header-ev .logo-header {
    max-width: 100px;
    vertical-align: center;
    padding: 5px 3px;
    height: 70px;
}
.header-ev .wrap-header {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}
/****HEADER****/

/****TABLA BOLETOS****/
#tabla-boletos-wrap {
    margin: 0px 0;
}
#tabla-boletos {
    width: 100%;
    max-width: 860px;
    margin: 0 auto;
    table-layout: auto;
    border-collapse: separate;
    border-spacing: 0px 20px;
}

#tabla-boletos thead {
    border-collapse: collapse;
}
#tabla-boletos thead tr {
}
#tabla-boletos thead tr th {
    line-height: 20px;
    text-align: left;
    font-size: 12px;
    font-weight: 300;
}
#tabla-boletos thead tr th:nth-child(4) i {
    display: none;
}
#tabla-boletos thead tr th:nth-child(1) {
    padding-left: 70px;
    width: 300px;
}
#tabla-boletos thead tr th:nth-child(2) {
    padding-left: 70px;
    width: 300px;
}
#tabla-boletos thead tr th:nth-child(3),
#tabla-boletos tbody tr td:nth-child(3) {
    width: 140px;
    text-align: center;
}
#tabla-boletos thead tr th:nth-child(4) {
    text-align: center;
}

#tabla-boletos tbody {
    padding: 20px 0;
    border-radius: 8px;
    overflow: hidden;
}
#tabla-boletos tbody tr {
    border-radius: 8px;
    overflow: hidden;
}
#tabla-boletos tbody tr td {
    background: #fff;
    padding: 20px 5px;
    text-align: left;
    font-size: 14px;
    font-family: var(--font);
    color: rgb(144, 144, 144);
    border-top: solid 1px #e4e8ed;
    border-bottom: solid 1px #e4e8ed;
}
#tabla-boletos tbody tr td:first-child {
    border-left: solid 1px #e4e8ed;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}
#tabla-boletos tbody tr td:last-child {
    border-right: solid 1px #e4e8ed;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}
#tabla-boletos tbody tr td:first-child {
    font-weight: bold;
}
#tabla-boletos tbody tr td:last-child {
}

#tabla-boletos tbody tr td:nth-child(1) {
    padding-left: 40px;
    width: 300px;
}
#tabla-boletos tbody tr td:nth-child(2) {
    padding-left: 70px;
    width: 300px;
}
#tabla-boletos tbody tr.agotado td {
    background: #f8f8f8;
    border-color: #f8f8f8 !important;
}
#tabla-boletos tbody tr.agotado td:nth-child(2),
#tabla-boletos tbody tr.agotado td:nth-child(3) {
    color: rgba(153, 153, 153, 0.5);
}
#tabla-boletos tbody tr td:nth-child(4) {
    text-align: center;
}
#tabla-boletos tbody tr td .ctrl-cant {
    display: flex;
    margin-right: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#tabla-boletos tbody tr td .ctrl-cant button {
    font-size: 13px;
    height: 32px;
    width: 32px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    border-radius: 50%;
    -webkit-transition: linear all 0.2s;
    -o-transition: linear all 0.2s;
    transition: linear all 0.2s;
    position: relative;
    outline: none !important;
}
#tabla-boletos tbody tr td .ctrl-cant label {
    font-size: 16px;
}
#tabla-boletos tbody tr td .ctrl-cant label {
    margin-bottom: 0px;
    line-height: 27px !important;
}
@media (max-width: 440px) {
    #tabla-boletos tbody tr td .ctrl-cant {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
.covert-map {
    width: 100%;
    background: transparent;
    height: 100vh;
    position: absolute;
    top: 0;
    bottom: 0;
    display: none;
}

.cover-show {
    display: block !important;
}

#tabla-boletos tbody tr td .ctrl-cant button i {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-53%, -50%);
    -moz-transform: translate(-53%, -50%);
    -ms-transform: translate(-53%, -50%);
    -o-transform: translate(-53%, -50%);
    transform: translate(-53%, -50%);
}
#tabla-boletos tbody tr td .ctrl-cant i.icon-menos-simple {
}

#tabla-boletos tbody tr:not(.activo) td .ctrl-cant button#rem-ent {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
}

#tabla-boletos tbody tr td .ctrl-cant label {
    width: 25px;
    font-size: 16px;
    display: inline-block;
    font-weight: bold;
    color: rgb(0, 0, 0);
    margin-bottom: 0;
}
.btn-get-tk {
    margin: 0 auto 70px;
    max-width: 345px;
}
.btn-skin,
.btn-skin:hover,
.btn-skin:focus {
    display: block;
    width: 100%;
    line-height: 45px;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
    -webkit-transition: linear all 0.2s;
    -o-transition: linear all 0.2s;
    transition: linear all 0.2s;
    outline: none;
}
/****TABLA BOLETOS****/

/****TABLA BOLETOS GRATIS****/
#tabla-boletos.gratis {
    max-width: 680px;
}
#tabla-boletos.gratis thead tr th:nth-child(1) {
    width: 50%;
}
#tabla-boletos.gratis tbody tr td:nth-child(2),
#tabla-boletos.gratis thead tr th:nth-child(2) {
    text-align: center;
}
/****TABLA BOLETOS GRATIS****/

/****PAGINA PAGO****/
header .banner-evento #counter {
    float: right;
    padding: 0 40px;
    border-left: solid 1px rgba(244, 244, 244, 1);
    margin-top: 19px;
}

header .banner-evento #counter .wrap {
    height: 87px;
    position: relative;
    overflow: hidden;
}
header .banner-evento #counter p {
    display: block;
    position: relative;
    top: 50%;
    left: 100%;
    font-size: 12px;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    -webkit-transition: linear all 0.3s;
    -o-transition: linear all 0.3s;
    transition: linear all 0.3s;
}
header .banner-evento #counter.show .wrap p {
    left: 0;
}
header .banner-evento #counter p b {
    display: block;
    font-size: 26px;
    font-weight: bold;
}
header .banner-evento #counter.short p b {
    color: red;
}

header.fixed .banner-evento #counter .wrap {
    height: 70px;
}
header.fixed .banner-evento #counter {
    margin-top: 12px;
}

.wrap-pago {
    position: relative;
}
.wrap-pago .title-g {
    margin: 30px 0;
    font-family: var(--font);
}
.wrap-pago .content {
    float: left;
    max-width: 950px;
    width: 100%;
}
.wrap-pago .resumen {
    width: 340px;
    float: right;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    margin-bottom: 30px;
    z-index: 50;
}
.wrap-pago .wrap-resumen {
    border-radius: 8px;
    min-height: 150px;
    background: #fff;
    border: solid 1px #e4e8ed;
}
.wrap-pago .wrap-resumen .tickets .title {
    display: block;
    font-size: 12px;
    color: rgb(180, 180, 180);
    margin-bottom: 10px;
    display: none;
}

.wrap-pago .wrap-resumen .tickets {
    padding: 1px 26px 0;
}

.wrap-pago .wrap-resumen .tickets a {
    display: block;
    line-height: 16px;
    margin: 25px auto 25px;
}
.wrap-pago .wrap-resumen .tickets.show a {
    margin: 25px auto 15px;
}
.wrap-pago .wrap-resumen .tickets a p:first-child {
    float: left;
}

.wrap-pago .wrap-resumen .tickets a p span {
    font-weight: bold;
    color: var(--color-ppal);
}
.wrap-pago .wrap-resumen .tickets.show a p:nth-child(2) {
    display: none;
    float: right;
}
.wrap-pago .wrap-resumen .tickets article {
    display: none;
}
.wrap-pago .wrap-resumen .tickets.show .title,
.wrap-pago .wrap-resumen .tickets.show article {
    display: block;
    margin-bottom: 20px;
}
.wrap-pago .wrap-resumen .tickets.show article {
    border-bottom: solid 1px rgb(235, 235, 235);
}
.wrap-pago .wrap-resumen .tickets article .ticket {
    display: block;
    line-height: 17px;
    font-weight: 400;
    margin-bottom: 15px;
    position: relative;
}
.wrap-pago .wrap-resumen .tickets article .ticket > i.icon-cerrar {
    position: absolute;
    cursor: pointer;
    right: -17px;
    top: 1px;
}

.wrap-pago .wrap-resumen .tickets article .ticket > i.icon-cerrar:hover {
    color: var(--color-ppal);
}

.wrap-pago .wrap-resumen .tickets article p:first-child {
    float: left;
    position: relative;
}

.wrap-pago .wrap-resumen .tickets article p:nth-child(3) {
    float: right;
}

#wrap-pago .wrap-resumen .tickets .title span {
    display: block;
    float: left;
    width: 20%;
    text-align: right;
    padding: 0 5px;
}
#wrap-pago .wrap-resumen .tickets .title span:first-child {
    width: 40%;
    text-align: left;
}
#wrap-pago .wrap-resumen .tickets .title span:last-child {
    text-align: right;
    width: 40%;
}

#wrap-pago .wrap-resumen .tickets article p {
    float: left !important;
    width: 20%;
    padding: 0 5px;
    text-align: right;
}
.wrap-pago .wrap-resumen .tickets article p:first-child {
    position: relative;
}
.wrap-resumen .tickets article .zona p:first-child span:first-child {
}
.wrap-pago .wrap-resumen .tickets article p:first-child span:last-child {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    text-transform: capitalize;
}
.wrap-pago .wrap-resumen .tickets .ticket .puestos {
    display: block;
    color: var(--color-content);
    font-size: 12px;
    text-transform: capitalize;
    margin-top: 10px;
    padding-left: 5px;
    float: left;
    width: 100%;
    line-height: 14px;
}
.wrap-resumen .tickets .ticket .puestos span {
}

#wrap-pago .wrap-resumen .tickets article p:first-child {
    width: 40%;
    text-align: left;
}
#wrap-pago .wrap-resumen .tickets article p:nth-child(3) {
    text-align: right;
    width: 40%;
}

#wrap-pago .wrap-resumen .tickets a p {
    float: left !important;
    width: 20%;
    padding: 0 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
}

#wrap-pago .wrap-resumen .tickets a p:first-child {
    width: 50%;
    text-align: left;
    font-weight: bold;
}
#wrap-pago .wrap-resumen .tickets a p:last-child {
    text-align: right;
    width: 30%;
    float: right !important;
}
.wrap-pago .wrap-resumen .impuestos {
    padding: 0 26px;
    margin-bottom: 20px;
}

.wrap-pago .wrap-resumen .impuestos a {
    display: block;
    margin-bottom: 10px;
}

.wrap-pago .wrap-resumen .impuestos .list {
}
.wrap-pago .wrap-resumen .impuestos .list p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.wrap-pago .wrap-resumen .impuestos .list p span {
    line-height: 17px;
    font-weight: 400;
    color: rgb(0, 0, 0);
}
.wrap-pago .wrap-resumen .impuestos .list p b {
}


#wrap-pago .wrap-resumen.con-total {
    position: relative;
}
#wrap-pago .wrap-resumen.con-total #total {
    padding: 20px 25px;
    bottom: 0;
    border-radius: 0 0 5px 5px;
    width: 100%;
    height: 83px;
}
#wrap-pago .wrap-resumen.con-total #total p {
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    line-height: 20px;
}

#wrap-pago .wrap-resumen.con-total #total p span {
    float: right;
}

#wrap-pago .wrap-resumen.con-total #total .fee-included {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display: inline-block;
    text-align: right;
    position: relative;
    float: right;
}

.wrap-pago .wrap-resumen .promo {
    padding: 0 26px;
}
.wrap-pago .wrap-resumen .promo > a.ver-input {
    text-decoration: underline;
    line-height: 16px;
    margin-bottom: 30px;
    display: none;
    padding: 0 5px;
}
.wrap-pago .wrap-resumen .promo > a.ver-input.show {
    display: block;
    cursor: pointer;
}
.wrap-pago .wrap-resumen .promo article {
    padding-right: 115px;
    position: relative;
    display: none;
}
.wrap-pago .wrap-resumen .promo article.show {
    display: block;
}

.wrap-pago .wrap-resumen .promo p {
    color: var(--color-content);
    line-height: 16px;
    position: relative;
    margin-bottom: 30px;
    display: none;
}

.wrap-pago .wrap-resumen .promo p span {
    display: block;
    float: left;
    padding: 0 5px;
    color: var(--color-entradas);
}
.wrap-pago .wrap-resumen .promo p span:nth-child(2) {
    float: right;
}
.wrap-pago .wrap-resumen .promo p i {
    position: absolute;
    cursor: pointer;
    right: -17px;
    top: 1px;
}
.wrap-pago .wrap-resumen .promo p i:hover {
    color: var(--color-ppal);
}

.wrap-pago .wrap-resumen .promo article .wrap-input {
    min-height: 35px;
    margin-bottom: 30px;
}
.wrap-pago .wrap-resumen .promo article .wrap-input input {
    height: 35px;
    line-height: 33px;
}
.wrap-pago .wrap-resumen .promo article button {
    position: absolute;
    right: 3px;
    top: 0;
    height: 35px;
    text-align: center;
    background: none;
    width: 100px;
    -webkit-transition: linear all 0.15s;
    -o-transition: linear all 0.15s;
    transition: linear all 0.15s;
    line-height: 33px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 2px;
}
.wrap-pago .wrap-resumen .promo article .icon-cerrar {
    position: absolute;
    cursor: pointer;
    right: -17px;
    top: 11px;
    color: rgb(153, 153, 153);
}

.wrap-pago .wrap-resumen .promo .errorText {
    color: red;
    font-size: 10px !important;
    position: absolute;
    left: 0;
    bottom: -17px;
    font-weight: 400;
    letter-spacing: 0.05em;
}

.nota-fees {
    display: block;
    text-align: right;
    color: var(--color-content);
    font-size: 12px;
    margin-top: 15px;
}
.wrap-pago .resumen .donaciones {
    margin-top: 30px;
    border-radius: 8px;
    overflow: hidden;
    font-family: var(--font);
    background: #fff;
    border: solid 1px #e4e8ed;
}
.wrap-pago .resumen .donaciones img {
    display: block;
    width: 100%;
    margin: 0 auto;
}
.wrap-pago .resumen .donaciones .btns {
    position: relative;
    padding: 10px 115px 10px 10px;
    min-height: 60px;
    background: rgb(244, 244, 244);
}
.wrap-pago .resumen .donaciones .btns a {
    line-height: 35px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    display: block;
    border-radius: 3px;
    text-decoration: none;
    width: 100px;
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.wrap-pago .resumen .donaciones .btns p {
    font-size: 16px;

    line-height: 40px;
    padding-left: 6px;
}
.wrap-pago .resumen .donaciones .btns.multiple {
    padding: 10px 10px 10px 10px;
    background: none;
    border-radius: 0 0 8px 8px;
}
.wrap-pago .resumen .donaciones .btns.multiple > p {
    font-size: 12px;
    line-height: 19px;
    color: rgb(124, 124, 124);
    margin-bottom: 5px;
}
.wrap-pago .resumen .donaciones .btns.multiple > p span b {
    font-size: 14px;
}
.wrap-pago .resumen .donaciones .btns.multiple > p > span {
    display: block;
    margin-bottom: 15px;
}
.wrap-pago .resumen .donaciones .btns.multiple > p > span b {
}
.wrap-pago .resumen .donaciones .btns.multiple > p > span span {
    font-size: 10px;
    text-decoration: underline;
    cursor: pointer;
}
.wrap-pago .resumen .donaciones .btns.multiple > div:nth-child(2) a {
    display: inline-block;
    position: relative;
    margin-right: 10px;
    line-height: 20px;
    width: auto;
    margin-bottom: 10px;
    padding: 0 10px;
    height: 20px;
    border: solid 1px rgb(216, 216, 216);
    background: none;
    border-radius: 10px;
    top: 0;
    left: 0;
    cursor: pointer;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);

    font-size: 12px;
    font-weight: 400;
}
.wrap-pago .resumen .donaciones .btns.multiple > div:nth-child(3) {
    display: none;
}

.wrap-pago .resumen .donaciones .btns.multiple > div:nth-child(2) a.active,
.wrap-pago .resumen .donaciones .btns.multiple > div:nth-child(2) a:hover {
    color: rgb(255, 255, 255);
    font-weight: normal;
}
.wrap-pago .resumen .donaciones .btns.multiple.show-input > div:nth-child(2) {
    display: none;
}
.wrap-pago .resumen .donaciones .btns.multiple.show-input > div:nth-child(3) {
    display: block;
    padding-right: 144px;
    position: relative;
}
.wrap-pago
    .resumen
    .donaciones
    .btns.multiple.show-input
    > div:nth-child(3)
    .wrap-input {
    margin-bottom: 0;
    height: 35px;
    min-height: 35px;
}
.wrap-pago
    .resumen
    .donaciones
    .btns.multiple.show-input
    > div:nth-child(3)
    .wrap-input
    input {
    height: 35px;
    line-height: 33px;
    margin-bottom: 0px;
}
.wrap-pago
    .resumen
    .donaciones
    .btns.multiple.show-input
    > div:nth-child(3)
    button {
    position: absolute;
    right: 20px;
    top: 0;
    height: 35px;
    text-align: center;
    background: none;
    width: 114px;
    -webkit-transition: linear all 0.15s;
    -o-transition: linear all 0.15s;
    transition: linear all 0.15s;
    line-height: 33px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 2px;
}
.wrap-pago
    .resumen
    .donaciones
    .btns.multiple.show-input
    > div:nth-child(3)
    button.inactive {
}
.wrap-pago .resumen .donaciones .btns.multiple.show-input > div:nth-child(3) i {
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
}

.wrap-pago .wrap-resumen .don {
    padding: 0 25px;
}
.wrap-pago .wrap-resumen .don article {
    margin-bottom: 25px;
}
.wrap-pago .wrap-resumen .don p {
    color: var(--color-entradas);
    line-height: 16px;
    position: relative;
}
.wrap-pago .wrap-resumen .don p span {
    display: block;
    float: left;
    padding: 0 5px;
}
.wrap-pago .wrap-resumen .don p span:nth-child(2) {
    float: right;
}
.wrap-pago .wrap-resumen .don p i {
    position: absolute;
    cursor: pointer;
    right: -17px;
    top: 1px;
    color: var(--color-content);
}
.wrap-pago .wrap-resumen .don p .entrada {
    color: rgb(153, 153, 153);
}
.wrap-pago .wrap-resumen .don p i:hover {
    color: var(--color-ppal);
}
.wrap-resumen .don {
  padding: 0 25px;
}
.wrap-resumen .don article {
  margin-bottom: 25px;
}
.wrap-resumen .don p {
  color: var(--color-entradas);
  line-height: 16px;
  position: relative;
}
.wrap-resumen .don p span {
  display: block;
  float: left;
  padding: 0 5px;
}
.wrap-resumen .don p span:nth-child(2) {
  float: right;
}
.wrap-resumen .don p i {
  position: absolute;
  cursor: pointer;
  right: -17px;
  top: 1px;
  color: var(--color-content);
}
.wrap-resumen .don p .entrada {
  color: rgb(153, 153, 153);
}
.wrap-resumen .don p i:hover {
  color: var(--color-ppal);
}
#wrap-pago .datos-cli,
    #wrap-pago .datos-card {
        border-radius: 8px;
        background: #fff;
        border: solid 1px #e4e8ed;
    }
@media (min-width: 577px) {
    #wrap-pago .datos-cli,
    #wrap-pago .datos-card {
        padding: 30px 30px;
    }
}
@media (max-width: 576px) {
    #wrap-pago .datos-card {
        padding: 15px 0px 30px;
    }
}
#wrap-pago .datos-cli section,
#wrap-pago .datos-card .wrap-card-u {
    max-width: 100%;
    margin: 0 auto;
}
#wrap-pago .datos-card {
    margin-bottom: 30px;
}

.luka-mp,
.luka-wrapForm,
.luka-wrapForm .container-tm,
.luka-wrapFormCcard .container-tm,
.luka-terminos,
.luka-btn-pay {
    max-width: 100% !important;
}

#wrap-pago .info-pago,
#wrap-pago .terminos,
#wrap-pago .btn-pago {
    width: 100%;
    max-width: 100%;
    float: left;
}
#wrap-pago .info-pago .metodos {
    margin: 0 -10px;
    margin-bottom: 60px;
}
#wrap-pago .info-pago .metodos article {
    float: left;
    width: 20%;
    padding: 0 10px;
}
#wrap-pago .info-pago .metodos article p {
    font-size: 12px;
    text-align: center;
}
#wrap-pago .info-pago .metodos article .wrap {
    background: #fff;
    border-radius: 3px;
    /* border: solid 1px rgb(244,244,244); */
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;
    overflow: hidden;
    max-height: 80px;
    transition: 0.3s;
}

#wrap-pago .info-pago .metodos article .wrap:before {
    position: absolute;
    margin-top: 120px;
    margin-left: 6.5%;
    transform: translateX(-50%);
    border: solid 1px #d6d6d6;
    border-radius: 50%;
    content: "";
    display: block;
    width: 20px;
    height: 20px;
}

#wrap-pago .info-pago .metodos article .wrap.luka-pay:before {
    display: none;
}

#wrap-pago .info-pago .metodos article .wrap.selected:after {
    position: absolute;
    margin-top: 44px;
    margin-left: 6.5%;
    transform: translateX(-50%);
    background: var(--c1);
    border-radius: 50%;
    content: "";
    display: block;
    width: 12px;
    height: 12px;
}
#wrap-pago .info-pago .metodos article .wrap .wrap-logo {
    position: relative;
    width: 100%;
    height: 80px;
}
#wrap-pago .info-pago .metodos article .wrap img,
#wrap-pago .info-pago .metodos article .wrap span {
    position: absolute;
    left: 50%;
    display: block;
    top: 50%;
    max-width: 96%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#wrap-pago .info-pago .metodos article .wrap.selected .wrap-logo {
    height: 80px;
}

#wrap-pago .info-pago .info-met {
    margin-top: 30px;
}
#wrap-pago .info-pago .info-met#transf .wrap-m {
    max-width: 400px;
    margin: 60px auto;
    margin-bottom: 0px;
}
#wrap-pago .info-pago .info-met#transf .wrap-m .s-title {
    text-align: center;
    font-size: 16px;
    color: rgb(144, 144, 144);
    margin-bottom: 10px;
}
#wrap-pago .info-pago .info-met#transf .wrap-m .s-title a {
    font-size: 14px;
    color: rgb(9, 203, 207);
}
#wrap-pago #label-banco {
    border: solid 1px rgb(212, 212, 212);
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
    position: relative;
    height: 60px;
    background: #fff;
}
#wrap-pago #label-banco span {
    display: block;
    line-height: 12px;
    color: rgb(9, 203, 209);
    padding-left: 5px;
    font-size: 12px;
    margin-bottom: 10px;
}
#wrap-pago #label-banco select {
    font-size: 18px;
    line-height: 18px;
    color: rgb(144, 144, 144);
    border: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
    background: none;
    padding: 35px 20px 5px 20px;
}
#wrap-pago .info-met {
    display: none;
}
#wrap-pago .info-met .wrap-content-form {
    border-radius: 5px;
    border: solid 1px rgb(214, 214, 214);
    padding: 30px 30px;
    margin-bottom: 30px;
}
#wrap-pago .info-met .wrap-content-form .wrap-m {
    max-width: 810px;
    margin: 0 auto;
}

#wrap-pago .info-met#tdc {
    margin: 30px auto 0 auto;
}
#wrap-pago .info-met#tdc .s-title {
    font-size: 16px;
    text-align: center;
    color: var(--color-content);
    margin-bottom: 20px;
}
#wrap-pago .terminos {
    display: block;
    font-size: 12px;
    position: relative;
    text-align: center;
    color: rgb(153, 153, 153);
}
.tooltip-term {
    height: 60px;
    width: 360px;
    position: absolute;
    top: -70px;
    border-radius: 5px;
    text-align: center;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    display: none;
}
.tooltip-term i {
    display: inline-block;
    font-size: 30px;
    line-height: 60px;
}
.tooltip-term span {
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    margin-left: 5px;
    line-height: 60px;
    vertical-align: top;
}
.tooltip-term:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: 78px;
    bottom: -10px;
    position: absolute;
}
#wrap-pago .terminos input {
    border: solid 1px rgb(153, 153, 153);
    display: inline-block;
    margin-right: 5px;
}
#wrap-pago .terminos a {
    text-decoration: underline;
}
#wrap-pago .btn-pago {
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.08);
    width: 100%;
    margin: 30px auto;
}
#wrap-pago .btn-pago img {
    display: inline-block;
    vertical-align: top;
    margin-top: 15px;
    margin-right: 10px;
}

#wrap-pagoGratis .wrap-resumen .tickets article p:nth-child(2) {
    float: right;
}

#wrap-pagoGratis .wrap-content {
    border-radius: 5px;
    border: solid 1px rgb(214, 214, 214);
    padding: 30px 30px;
}
#wrap-pagoGratis .wrap-content section {
    max-width: 810px;
    margin: 0 auto;
}
#wrap-pagoGratis .btn-pago-gratis {
    background: var(--color-ppal);
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.08);
    width: 100%;
    margin: 70px auto;
}

.modalAlert .modal-dialog {
    max-width: 380px;
}
.modalAlert .top {
    height: 100px;
    position: relative;
    font-size: 50px;
    background: var(--c1);
    border-radius: 5px 5px 0 0;
}
.modalAlert .top svg{
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
}
.modalAlert .top img,
.modalAlert .top i {
    position: absolute;
    top: 50%;
    left: 50%;
    color: rgb(255, 255, 255);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.modalAlert .content {
    padding: 0 30px;
    max-width: 100% !important;
}
.modalAlert .content .title {
    font-size: 16px;

    font-weight: bold;
    line-height: 16px;
    margin-top: 20px;
    font-family: var(--font);
    margin-bottom: 20px;
    text-align: center;
}
.modalAlert .content p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 300;
}
#modalExpired .content a {
    float: right;
    margin-bottom: 20px;
    line-height: 35px;
    width: 150px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
}
#modalConfirm {
    z-index: 1001;
}

#modalAda .content a,
#modalConfirm .content a {
    float: right;
    margin-bottom: 20px;
    line-height: 35px;
    width: auto;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    margin-right: 0px;
    cursor: pointer;
}

#modalConfirm button {
    float: right;
    margin-bottom: 20px;
    line-height: 35px;
    width: auto;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    margin-right: 0px;
    cursor: pointer;
    border: none;
    background: none;
}
#modalConfirm button#confirmacionBack-cancel {
    float: left;
    color: #ff0000 !important;
}

#modalAda .content a:nth-child(4),
#modalConfirm .content a:nth-child(4) {
    margin-right: 40px;
}

#modalTerminos .modal-content {
    padding: 40px;
    position: relative;
}
#modalTerminos .modal-content .cerrar {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
}

.cerrar-shared {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
#modalTerminos .modal-content .title {
    width: 100%;
    text-align: center;
    margin: 15px auto 35px;
}
#modalTerminos .modal-content .wrap-terms {
    width: 100%;
    padding: 0 10px;
}
#modalTerminos .modal-content .wrap-terms .text {
    max-height: 330px;
    overflow: auto;
    border: solid 1px rgb(214, 214, 214);
    padding: 15px;
    font-weight: 300;
}
.btn-modal-term {
    background: var(--color-ppal);
    max-width: 250px;
    margin: 30px auto 0;
    cursor: pointer;
}

#modalMoreDon .modal-dialog {
    max-width: 900px;
}
#modalMoreDon .modal-content {
    overflow: hidden;
}
#modalMoreDon .modal-content .title {
    width: 100%;
    text-align: center;
    margin: 25px auto 25px;
}
#modalMoreDon .modal-content .wrap-terms {
    width: 100%;
    padding: 0 10px;
}
#modalMoreDon .modal-content .wrap-terms .text {
    max-height: 330px;
    overflow: auto;
    border: solid 1px rgb(214, 214, 214);
    padding: 15px;
    font-weight: 300;
}
#modalMoreDon .modal-content img {
    display: block;
    width: 100%;
}
#modalMoreDon .modal-content .conten-don {
    padding: 0 40px 40px 40px;
    position: relative;
}
#modalFailure .content a {
    float: right;
    margin-bottom: 20px;
    line-height: 35px;
    width: 150px;
    font-size: 14px;
    color: var(--color-ppal);
    text-align: center;
    font-weight: bold;
}
/****PAGINA PAGO****/

/****PAGINA MAPA****/
#mapa-boletos-wrap {
    position: relative;
    margin: 0 auto 25px;
    margin-bottom: 0;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
}
#mapa-boletos-wrap .mapa {
    border: solid 1px rgb(214, 214, 214);
    border: none;
    border-radius: 5px;
    width: 100%;
    padding-top: "3%";
}

/*#main #wrap-ppal #wrap-pago{
  font-family: "Open-Sans" !important;
}*/
#main #wrap-ppal.pageMapa {
    padding-bottom: 0;
    margin-top: 30px;
    /*ont-family: "Open-Sans" !important;*/
}
.wrap-input.error input {
    border-color: #ff0000;
}
.wrap-input.error select {
    border-color: #ff0000;
}
.wrap-input.error .errorText,
.terminos .errorText {
    font-size: 10px !important;
    position: absolute;
    left: 0;
    bottom: -14px;
    font-weight: 400;
    letter-spacing: -0.03em;
    color: #ff0000;
}
.terminos .errorText {
    position: unset;
}
.terminos .terminos-condiciones {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.pageMapa #wrap-cont {
    position: relative;
    min-height: 100%;
    display: inline-block;
    width: 100%;
}
.pageMapa #wrap-cont .container .mapa,
.pageMapa #wrap-cont .container {
    height: 100%;
    min-height: 100%;
}
.pageMapa #wrap-cont .container #mapa-boletos-wrap {
}
#mapa-boletos-wrap .mapa .container-map {
    display: block;
    width: 100%;
    background: #fff;
    height: 100%;
    border-radius: 8px;
    /* height: calc(100vh - 205px); */
    max-height: 820px;
    border: solid 1px #e4e8ed;
    overflow: hidden;
}
#mapa-boletos-wrap .mapa iframe {
    display: block;
    width: 100%;
    min-height: 100%;
}

#mapa-boletos-wrap .wrap-boletos {
    width: 375px;
}
@media (min-width: 1280px) {
    #mapa-boletos-wrap .mapa {
        width: 100%;
        width: calc(100% - 405px);
    }
}
#mapa-boletos-wrap .wrap-boletos .boletos {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 30px;
    border: solid 1px #e4e8ed;
}
#mapa-boletos-wrap .wrap-boletos .boletos .title {
    line-height: 51px;
    padding: 0 15px;
    font-weight: bold;
    font-family: var(--font);
    border-bottom: solid 1px #e4e8ed;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content {
    min-height: 188px;
    max-height: 300px;
    overflow: auto;
    position: relative;
}

ul.cart-detail {
    display: flex;
    padding: 0;
    list-style: none;
    margin: 0;
}

ul.cart-detail li {
    flex: 4;
}

ul.cart-detail li:first-child {
    flex: 6;
}

ul.cart-detail li:last-child {
    flex: 1;
}

ul.cart-detail .type {
    font-size: 11px;
    display: block;
}

ul.cart-detail .icon-cerrar {
    color: #999;
}

ul.cart-detail .icon-cerrar:hover {
    cursor: pointer;
}
#mapa-boletos-wrap
    .wrap-boletos
    .boletos
    .content
    div:not(:first-child)
    .title-t {
    display: none;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .title-t {
    font-size: 12px;
    color: rgb(180, 180, 180);
    padding: 15px;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .title-t span {
    display: block;
    float: left;
    width: 50%;
    text-align: left;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .title-t span:nth-child(2) {
    width: 20%;
    text-align: center;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .title-t span:nth-child(3) {
    width: 30%;
    text-align: center;
    padding-right: 20px;
}

#mapa-boletos-wrap .wrap-boletos .boletos .content .item {
    font-size: 14px;
    padding: 0 15px;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 20px;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .item > span {
    position: relative;
    display: block;
    float: left;
    width: 50%;
    text-align: left;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .item > span:first-child {
    font-size: 12px;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .item > span {
    font-size: 14px;
    display: block;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .item > span:nth-child(2) {
    width: 20%;
    text-align: center;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .item > span:nth-child(3) {
    width: 30%;
    text-align: center;
    padding-right: 20px;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .item > span i {
    position: absolute;
    right: 0px;
    font-size: 15px;
    color: rgb(180, 180, 180);
    cursor: pointer;
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .item > span i:hover {
    color: var(--color-ppal);
}

#mapa-boletos-wrap .wrap-boletos .boletos .content .no-seats {
    font-size: 10px;
    font-weight: 300;
    color: #abaab2;
    position: absolute;
    font-weight: 400;
    font-size: 10.5px;
    top: 50%;
    width: 100%;
    text-align: center;
    display: none;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
#mapa-boletos-wrap .wrap-boletos .boletos .content .no-seats.show {
    display: block;
}
.no-seats.show img{
  margin: 0 auto;
}
#mapa-boletos-wrap .wrap-boletos a.btn-ticket-mapa {
    margin: 30px 0;
}

#wrapMapaSvg {
    padding: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}
#wrapMapaSvg svg {
    width: 100%;
    height: auto;
    max-width: 600px;
}
@media (min-width: 1024px) {
    /* #wrapMapaSvg svg {
    transform: rotate(-90deg);
  } */
  #wrapMapaSvg svg {
    aspect-ratio: 1.3 / 0.9;
    max-width: 100%;
  }
}
@media (max-width: 1024px) {
    .container-map-pres {
        height: auto;
        max-height: none !important;
    }
}
#wrapMapaSvg svg .sectionZoneSvg {
    cursor: pointer;
    transition: ease-in-out all 0.1s;
}
#wrapMapaSvg svg .sectionZoneSvg:hover {
    opacity: 0.8;
}
.btn-back-svg {
    position: absolute;
    left: 10px;
    z-index: 999;
    top: 10px;
    display: flex;
    align-items: center;
}
.btn-back-svg button {
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    margin-right: 15px;
}
.btn-back-svg button svg {
    width: 20px;
    height: 20px;
}
.btn-back-svg h3 {
    font-weight: bold;
    font-family: var(--font);
    color: var(--c4);
    margin: 0;
    pointer-events: none;
    padding: 2px 5px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.8);
    font-size: 14px;
}
.container-map-pres {
    min-height: 600px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.container-map .container {
    width: 100%;
    max-width: 670px;
}
.container-map #wrap-ppal {
    margin-top: 0px;
}
.luka-carousel-container {
    height: auto !important;
}

.luka .luka-wrapForm {
    background: #fff !important;
    border: solid 1px #e4e8ed !important;
    border-radius: 8px;
}

.luka-carousel-item:not(.luka-carousel__active-card) {
    background: #fff !important;
    border: solid 1px #e4e8ed !important;
    border-radius: 8px !important;
}
.luka-carousel__active-card {
    background: #fff !important;
    border-radius: 8px !important;
}

.luka-btn-pay {
    border-radius: 8px !important;
}

#wrapMapaSvg svg .disabled {
    cursor: not-allowed;
    fill: #acacac;
}
#wrapMapaSvg svg .disabled * {
    cursor: not-allowed;
    fill: #acacac;
}
/****PAGINA MAPA****/

/****PAGINA CONFIRMACION****/
.confirmacion .title-h {
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
    padding: 0 15px;
}
.confirmacion .header-ev,
.confirmacion .header {
    background: var(--c1);
}
.confirmacion .header-ev {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.confirmacion .header-confirm .header-ev {
    display: flex;
    flex-direction: column;
    max-height: 100% !important;
    height: auto;
    padding: 26px 0;
}
.confirmacion .header-confirm .header-ev .iconcheck {
    display: block;
    margin: 20px;
}
.confirmacion .header-confirm .header-ev .logo-home,
.confirmacion .header-confirm .header-ev .logo-home img {
    max-width: 249px  !important;
    max-height: 120px !important;
}
.confirmacion .title-h h1 {
    font-size: 40px;
    margin: 0 0;
    font-weight: bold;
    color: rgb(255, 255, 255);
    position: relative;
    color: var(--c4);
}
.confirmacion .title-h h1 span {
    -webkit-transition: linear all 0.4s;
    -o-transition: linear all 0.4s;
    transition: linear all 0.4s;
    opacity: 0;
    -webkit-transform: translate(-20%, 0);
    -ms-transform: translate(-20%, 0);
    -o-transform: translate(-20%, 0);
    transform: translate(-20%, 0);
    display: block;
    float: left;
    margin-right: 10px;
}
.confirmacion .title-h h1 span.showA {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
}
.head-abs {
    padding-top: 0px !important;
}
.confirmacion header,
.confirmacion header.fixed {
    position: relative !important;
    top: -0px;
    height: 100px;
}
.confirmacion #counter {
    display: none !important;
}
.confirmacion .news-letter a {
    display: flex !important;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
#wrap-pago-realizado {
    margin-top: 40px;
}
#wrap-pago-realizado .send {
    text-align: center;
    margin-bottom: 35px;
}

#wrap-pago-realizado .send i {
    display: inline-block;
    width: 55px;
    height: 55px;
    font-size: 55px;
    vertical-align: top;
    margin-right: 10px;
}
#wrap-pago-realizado .send p {
    display: inline-block;

    font-size: 14px;
    line-height: 24px;
    text-align: left;
    margin-top: 3px;
}
#wrap-pago-realizado .mail {
    font-size: 18px;
    font-weight: bold;
    display: block;
}
#wrap-pago-realizado .wrap-orden {
    text-align: center;
    margin-bottom: 40px;
    max-width: 1140px;
    margin: 0 auto 40px;
}
#wrap-pago-realizado .wrap-orden .s-title {
    margin-bottom: 10px;
    text-align: left;
}
#wrap-pago-realizado .wrap-orden .s-title strong {
    font-weight: 400;

    font-size: 14px;
}
#wrap-pago-realizado .wrap-orden .datos-o {
    display: block;
    margin: 0 -15px;
    padding: 20px;
    border: 1px solid #ededed;
    border-radius: 8px;
    background: #fff;
}
#wrap-pago-realizado .wrap-orden .datos-o > div {
    float: left;
    vertical-align: top;
    padding: 0 15px;
    width: 50%;
}
#wrap-pago-realizado .wrap-orden .datos-o > div:last-child .d-item {
    min-height: 305px;
    padding-bottom: 70px;
    position: relative;
}
#wrap-pago-realizado
    .wrap-orden
    .datos-o
    > div:last-child
    .d-item
    .news-letter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
}
#wrap-pago-realizado
    .wrap-orden
    .datos-o
    > div:last-child
    .d-item
    .news-letter
    .wrap {
    margin: 0 -10px;
}
#wrap-pago-realizado
    .wrap-orden
    .datos-o
    > div:last-child
    .d-item
    .news-letter
    .wrap
    > div {
    padding: 0 10px;
    display: block;
    float: left;
    width: 50%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
#wrap-pago-realizado
    .wrap-orden
    .datos-o
    > div:last-child
    .d-item
    .news-letter
    .wrap
    > div
    .lds-css {
    width: 45px;
    margin: 5px auto;
}
#wrap-pago-realizado
    .wrap-orden
    .datos-o
    > div:last-child
    .d-item
    .news-letter
    .wrap
    div
    a {
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(44, 55, 75, 0.1);
    line-height: 50px;
    border: solid 1px var(--c1);
    color: var(--c1);
    display: block;
    overflow: hidden;
}
#wrap-pago-realizado
    .wrap-orden
    .datos-o
    > div:last-child
    .d-item
    .news-letter
    .wrap
    div
    #addCalendar {
    background: var(--c1);
    color: #fff !important;
}

.wrap-orden
    .datos-o
    > div:last-child
    .d-item
    .news-letter
    .wrap
    div
    #addCalendar
    i::before {
    color: #fff !important;
}
#wrap-pago-realizado
    .wrap-orden
    .datos-o
    > div:last-child
    .d-item
    .news-letter
    .wrap
    div
    a
    img {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-top: 12px;
}
#wrap-pago-realizado
    .wrap-orden
    .datos-o
    > div:last-child
    .d-item
    .news-letter
    .wrap
    div
    a
    span {
    display: inline-block;
}
#wrap-pago-realizado .wrap-orden .datos-o div img {
    max-width: 100%;
}
#wrap-pago-realizado .wrap-orden .datos-o div .d-item {
    padding-top: 45px;
    text-align: left;
}
#wrap-pago-realizado .wrap-orden .datos-o div .d-item > div {
    display: block;
    font-size: 14px;

    margin-bottom: 25px;
}
#wrap-pago-realizado .wrap-orden .datos-o div .d-item > div .title {
    line-height: 16px;
    text-align: left;
    margin-bottom: 15px;
}

#wrap-pago-realizado .valores {
    display: block;
    margin: 0 -15px;
    margin-top: 30px;
}
#wrap-pago-realizado .valores article {
    float: left;
    vertical-align: top;
    padding: 0 15px;
    width: 50%;
    margin-bottom: 20px;
}
#wrap-pago-realizado .valores article .wrap {
    border-radius: 5px;
    border: solid 1px rgb(214, 214, 214);
    height: 160px;
    padding: 20px 65px 20px 130px;
    position: relative;
}
#wrap-pago-realizado .valores article .wrap img {
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    display: block;
}
#wrap-pago-realizado .valores article .wrap .content {
    position: relative;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: left;
    line-height: 27px;
}
#wrap-pago-realizado .valores article .wrap .content .title {
    margin-bottom: 5px;

    font-weight: bold;
}
#wrap-pago-realizado .valores article .wrap.estacionamiento {
    padding: 20px 15px 20px 130px;
}
#wrap-pago-realizado .valores article .wrap.estacionamiento .sitios {
    padding-left: 10px;
}
#wrap-pago-realizado .valores article .wrap.estacionamiento .sitios p {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 16px;
}
#wrap-pago-realizado .valores article .wrap.estacionamiento .sitios p span {
    font-weight: 400;
    display: block;
}

#wrap-pago-realizado .valores article .wrap.donacion-go {
}
#wrap-pago-realizado .valores article .wrap.donacion-go p {
    float: right;
    text-align: right;
    width: 100%;
    color: rgb(255, 255, 255) !important;
}
#wrap-pago-realizado .valores article .wrap.donacion-go a {
    display: block;
    line-height: 40px;
    border-radius: 2px;
    background: var(--color-ppal);
    border: solid 1px var(--color-ppal);
    text-transform: center;
    font-size: 14px;
    font-weight: bold;
    width: 150px;
    text-align: center;
    color: rgb(255, 255, 255);
    float: right;
    margin-top: 10px;
    -webkit-transition: linear all 0.3s;
    -o-transition: linear all 0.3s;
    transition: linear all 0.3s;
}
#wrap-pago-realizado .valores article .wrap.donacion-go a:hover {
    border: solid 1px var(--color-ppal);
    background: none;
    color: var(--color-ppal);
}
#wrap-pago-realizado .valores article .wrap.donacion {
    padding: 20px 20px;
    background-image: url(./../../images/manos.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
    border: none;
}
#wrap-pago-realizado .valores article .wrap .content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
#wrap-pago-realizado .valores article .wrap.calendar p {
    color: rgb(255, 255, 255);
}
#wrap-pago-realizado .valores article .wrap.donacion p {
    font-weight: bold;
}
#wrap-pago-realizado .valores article .wrap.donacion p span {
    font-weight: 300;
    font-size: 14px;
    display: block;
    margin-top: 10px;
}
#wrap-pago-realizado .valores article .wrap.donacion p > br {
    display: none;
}
#wrap-pago-realizado .wrap-mapa {
    margin-top: 10px;
}
#wrap-pago-realizado .wrap-mapa .s-title {
    margin-bottom: 20px;
}
#wrap-pago-realizado .wrap-mapa p {
    margin-bottom: 25px;
    text-align: left;
}
#wrap-pago-realizado .wrap-mapa iframe {
    display: block;
    width: 100%;
    border: solid 1px #ededed;
    border-radius: 8px;
}
#wrap-pago-realizado .redes-f {
    display: block;
    margin: 0 -25px;
    margin-top: 35px;
}
#wrap-pago-realizado .redes-f article {
    float: left;
    vertical-align: top;
    padding: 0 25px;
    width: 50%;
    margin-bottom: 60px;
}
#wrap-pago-realizado .redes-f article .title {
    font-size: 16px;
    font-weight: bold;

    text-align: left;
    margin-bottom: 25px;
}
#wrap-pago-realizado .redes-f article a {
    display: block;
    border-radius: 4px;
    overflow: hidden;
}
#wrap-pago-realizado .redes-f article img {
    max-width: 100%;
    display: block;
}
#wrap-pago-realizado .contact {
    margin-bottom: 60px;
}
#wrap-pago-realizado .contact p {
    font-size: 14px;

    line-height: 20px;
}
#wrap-pago-realizado .contact i {
    margin: 11px auto;
    font-size: 40px;
    display: block;
}
#wrap-pago-realizado .contact .number {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}
#wrap-pago-realizado .contact span {
    display: block;
    font-size: 12px;
    line-height: 18px;
}

#wrap-pago-realizado .nota {
    margin-top: 50px;
    padding: 0 23px;
    margin-bottom: 50px;
}
#wrap-pago-realizado .nota .title {
    font-size: 20px;
    font-weight: bold;

    text-align: left;
    margin-bottom: 20px;
}
#wrap-pago-realizado .nota p {
    font-size: 16px;
    color: rgb(124, 124, 124);
    line-height: 23px;
    text-align: justify;
}
body.f-min .confirmacion #wrap-ppal {
    padding-bottom: 0;
}
/****PAGINA CONFIRMACION****/

/****PAGINA Evento****/
body#evento {
    padding-top: 0;
}
body#evento .bg-top {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 550px;
    z-index: 1;
    background: #e9ebed;
}
body#evento #main {
    /* padding-top: 18px; */
}
body#evento #main .wrap-evento {
    max-width: 100%;
    margin: 0 auto 20px;
    border-radius: 8px;
    z-index: 2;
    position: relative;
    display: flex;

    margin-top: 40px;
}
@media (max-width: 1200px) {
    body#evento #main .wrap-evento {
        grid-template-columns: 55% auto;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento .title {
        font-size: 24px !important;
        line-height: 28px !important;
    }
    .wrap-evento .column-right .place span {
        font-weight: 500;
        font-size: 14px !important;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r span {
        font-size: 24px !important;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r p {
        font-size: 26px !important;
    }
}
@media (max-width: 992px) {
    body#evento #main .wrap-evento {
        grid-template-columns: 100%;
    }
}

body#evento #main .wrap-evento .banner-evento,
body#evento #main .wrap-evento .banner-donacion {
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
}
body#evento #main .wrap-evento .banner-donacion figure,
body#evento #main .wrap-evento .banner-evento figure {
    width: 100%;
    position: relative;
}
body#evento #main .wrap-evento .banner-evento button.share-button,
body#evento #main .wrap-evento .banner-evento label.favorite-button ,
body#evento #main .wrap-evento .banner-evento button.favorite-button {
    position: absolute;
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    bottom: 20px;
    
    border: none;
}
body#evento #main .wrap-evento .banner-evento label.share-button,
body#evento #main .wrap-evento .banner-evento button.share-button {
    margin: 0;
    right: 20px;
    cursor: pointer;
}
body#evento #main .wrap-evento .banner-evento label.favorite-button {
    right: 66px;
    margin: 0;
    cursor: pointer;
}
body#evento #main .wrap-evento .banner-evento figure img,
body#evento #main .wrap-evento .banner-donacion figure img {
    display: block;
    max-width: 100%;
    width: 100%;
}
body#evento #main .wrap-evento .banner-evento .info-evento {
    padding: 20px;
    position: relative;
    padding-right: 30px;
}
.info-evento .label_cont{
    display: flex;
    gap: 10px;
    padding: 20px 0;
    flex-wrap: wrap;
}
body#evento #main .wrap-evento .info-evento .label {
    display: inline-block;
    padding: 7px 10px;
    border-radius: 8px;
    font-size: 12px !important;
    font-weight: 600;
}
body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r {
    position: absolute;
    right: 20px;
    top: 30px;
    display: none;
}
body#evento #main .wrap-evento .banner-evento #fecha-r > div {
    display: block;
    font-size: 15px;
    text-transform: uppercase;
}
body#evento.eventoUnico
    #main
    .wrap-evento
    .banner-evento
    .info-evento
    #fecha-r {
    display: block;
}
body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r span {
    display: block;
    text-align: center;
    font-family: var(--font);
    font-weight: bold;
    line-height: 28px;
    font-size: 28px;
}
body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r p {
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
}
body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r.active {
    display: block;
}
body#evento #main .wrap-evento .banner-evento .info-evento .title {
    font-size: 28px;
    line-height: 32px;
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
    font-weight: bold;
    padding-right: 50px;
    color: var(--c4);
}
body#evento #main .wrap-evento .banner-evento .info-evento .tipo-funcion {
    padding-right: 50px;
}
body#evento #main .wrap-evento .banner-evento .info-evento .artista {
    font-size: 14px;
    font-weight: 300;
    display: block;
}
body#evento #main .wrap-evento .banner-evento .info-evento .tipo-funcion {
    font-size: 14px;
    font-weight: 300;
    display: block;
    margin-top: 10px;
}
body#evento #main .wrap-evento .banner-evento .info-evento .categoriaEvent {
    font-size: 11px;
    color: var(--c4);
    border: solid 1px var(--c4);
    border-radius: 8px;
    padding: 5px 10px;
    margin: 10px 0;
    text-transform: uppercase;
    display: inline-block;
}
body#evento #main .wrap-evento .banner-evento .info-evento .hasta,
body#evento #main .wrap-evento .banner-evento .info-evento .desde {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-transform: capitalize;
}
body#evento #main .wrap-evento .banner-evento .info-evento .desde,
body#evento #main .wrap-evento .banner-evento .info-evento .hasta {
    margin-top: 10px;
    margin-right: 10px;
}
body#evento #main .wrap-evento .banner-evento .info-evento .hasta b,
body#evento #main .wrap-evento .banner-evento .info-evento .desde b {
    font-weight: 500;
}
body#evento #main .wrap-evento .banner-evento .share-precio {
    position: absolute;
    bottom: 7px;
    max-width: 435px;
    width: 100%;
    right: 0;
    padding: 0 30px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
body#evento #main .wrap-evento .banner-evento .share-precio i {
    float: left;
    display: block;
    font-size: 16px;
    font-weight: bold;
    vertical-align: top;
    margin-top: 20px;
    cursor: pointer;
}
body#evento.gratis #main .wrap-evento .banner-evento .share-precio i {
    margin-top: 0;
}
body#evento #main .wrap-evento .precio {
    float: right;
    text-align: right;
    margin-top: 80px;
    margin-bottom: 40px;
}
body#evento #main .wrap-evento .precio .change-currency {
    font-size: 16px !important;
    font-weight: bold;
    letter-spacing: 0.03em;
    margin-bottom: 10px !important;
}
body#evento #main .wrap-evento .precio span {
    display: block;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}
body#evento #main .wrap-evento .precio b {
    font-size: 24px;
    display: block;
    font-weight: 500;
    line-height: 32px;
}

.change-currency {
    font-size: 11px !important;
    cursor: pointer;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-decoration: underline;
    color: var(--c1);
}
body#evento #main .wrap-evento .calendar-book {
    position: relative;
    z-index: 20;
    margin-top: 40px;
}
body#evento #main .wrap-evento .calendar-book .calendar {
    position: relative;
    width: 100%;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-box-shadow: 0 0 10px 0 rgb(210, 210, 210, 0.5);
    -webkit-box-shadow: 0 0 10px 0 rgb(210, 210, 210, 0.5);
    box-shadow: 0 0 10px 0 rgb(210, 210, 210, 0.5);
    cursor: pointer;
    background: rgb(255, 255, 255);
}
body#evento
    #main
    .wrap-evento
    .calendar-book
    .calendar
    .fechas-estandar
    .item.sel {
    padding-left: 40px;
    padding-right: 30px;
    text-transform: capitalize;
}
body#evento #main .wrap-evento .calendar-book .calendar.unico {
    box-shadow: none;
}
body#evento #main .wrap-evento .calendar-book .calendar .wrap-opciones {
    max-height: 350px;
    overflow-y: auto;
    position: absolute;
    right: 0;
    border-radius: 8px;
    top: 100%;
    margin-top: 5px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgb(210, 210, 210, 0.5);
    padding: 0 30px;
    z-index: 2;
}

body#evento
    #main
    .wrap-evento
    .calendar-book
    .calendar
    .wrap-opciones.datepicker {
    width: 100%;
}

@media (min-width: 991px) {
    .wrap-opciones {
        min-width: 550px;
    }
    .calendar-fix .wrap-opciones {
        min-width: 500px;
    }
}

@media (min-width: 970) {
    .wrap-opciones {
        min-width: 370px;
    }
}
.wrap-opciones .ver-calendario {
    font-size: 12px;
    float: right;
    margin-bottom: 10px;
    border: none;
    background: none;
}
body#evento .calendar .wrap-opciones.datepicker {
    margin-left: -20px;
    padding: 10px 10px 20px 10px !important;
}
body#evento .calendar .wrap-opciones.datepicker .ui-widget.ui-widget-content {
    width: 100% !important;
    border: none;
}
body#evento
    .calendar
    .wrap-opciones.datepicker
    .ui-widget.ui-widget-content
    .ui-datepicker-year {
    display: none;
}
.calendar
    .wrap-opciones.datepicker
    .ui-widget.ui-widget-content
    .ui-widget-header {
    background: none;
    border: none;
    font-size: 12px;
    text-transform: uppercase;
    font-family: var(--font);
    font-weight: 400;
}
.calendar .wrap-opciones.datepicker .ui-datepicker th {
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
}
.calendar .wrap-opciones.datepicker .ui-datepicker td,
.ui-datepicker td {
    padding: 10px !important;
}
.calendar .wrap-opciones.datepicker .ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    text-align: center;
    text-decoration: none;
    background: none !important;
    border: none !important;
}

.calendar
    .wrap-opciones.datepicker
    .ui-datepicker-multi
    .ui-datepicker-group.ui-datepicker-group-first {
    border-right: solid 1px rgb(244, 244, 244);
    padding-right: 10px;
}
.calendar .wrap-opciones.datepicker .ui-state-highlight,
.calendar .wrap-opciones.datepicker .ui-widget-content .ui-state-highlight,
.calendar .wrap-opciones.datepicker .ui-widget-header .ui-state-highlight {
    font-weight: bold;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
    opacity: 1;
}

body#evento #main .wrap-evento .calendar-book .calendar .wrap-opciones.show {
    display: block;
}
body#evento .calendar .wrap-opciones article {
    padding: 20px 0;
    border-top: solid 1px rgb(244, 244, 244);
    position: relative;
    padding-right: 180px;
}
body#evento .calendar .wrap-opciones article .label {
    font-size: 10px;
    line-height: 13px;
    padding: 3px 15px;
    border-radius: 2px;
    margin-top: 10px;
    float: left;
    margin-right: 10px;
}
body#evento .calendar .wrap-opciones article.agotado {
    cursor: default;
}

body#evento .calendar .wrap-opciones article.agotado .dir,
body#evento .calendar .wrap-opciones article.agotado .fecha,
body#evento .calendar .wrap-opciones article.agotado .label,
body#evento .calendar .wrap-opciones article.agotado > div {
    opacity: 0.3;
}
body#evento .calendar .wrap-opciones article.agotado .label {
    border: none;
    background: rgb(118, 118, 118);
    color: rgb(255, 255, 255);
}
body#evento .calendar .wrap-opciones article .dir {
    float: left;
    width: 100%;
}

body#evento .calendar .wrap-opciones article .fecha {
    position: absolute;
    right: 0;
    top: 20px;
    text-align: right;
    text-transform: capitalize;
}
body#evento .calendar .wrap-opciones article .fecha p,
body#evento .calendar .wrap-opciones article .dir p {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
}
body#evento .calendar .wrap-opciones article .fecha span,
body#evento .calendar .wrap-opciones article .dir span {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
}

body#evento #main .wrap-evento .calendar-book .calendar .item.sel {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding: 15px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}
body#evento #main .wrap-evento .calendar-book .calendar.unico .item.sel {
    cursor: default;
    padding-left: 25px;
}
body#evento #main .wrap-evento .calendar-book .calendar.unico i.icon-calendar {
    left: 0 !important;
}

body#evento #main .wrap-evento .calendar-book .calendar i.icon-calendar {
    position: absolute;
    font-size: 17px;
    left: 15px;
    top: 18px;
    pointer-events: none;
}
body#evento #main .wrap-evento .calendar-book .calendar i.icon-angle-d {
    position: absolute;
    font-size: 17px;
    right: 15px;
    top: 18px;
    pointer-events: none;
}
body#evento #main .wrap-evento .calendar-book .calendar i.icon-angle-d {
    font-size: 8px;
    top: 23px;
}
.button-book,
body#evento #main .wrap-evento .calendar-book .btn-gratis {
    width: 100%;
    line-height: 50px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    -webkit-transition: linear all 0.3s;
    -o-transition: linear all 0.3s;
    transition: linear all 0.3s;
}
body#evento #main .wrap-evento .descripcion {
    padding: 30px 20px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 30px;
}
body#evento #main .wrap-evento .video,
body#evento #main .wrap-evento .como-llegar {
    padding: 30px 20px;
    margin-top: 30px;
    border-radius: 8px;
    background: #fff;
}

body#evento #main .wrap-evento .descripcion .desccorta {
    margin-top: 35px;
}
body#evento #main .wrap-evento .descripcion .title,
body#evento #main .wrap-evento .map .title {
    margin-bottom: 25px;
}
body#evento #main .wrap-evento .descripcion p,
body#evento #main .wrap-evento .map p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    white-space: pre-line;
}
body#evento #main .wrap-evento .video {
    margin-bottom: 35px;
}
body#evento #main .wrap-evento .video iframe {
    display: block;
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
}
body#evento #main .wrap-evento .map {
    float: right;
    width: 36%;
    vertical-align: top;
    margin-top: 35px;
}
body#evento #main .wrap-evento .map iframe {
    margin-top: 30px;
    border-radius: 5px;
}

.modalRegistrarGratis {
}
.modalRegistrarGratis .modal-content {
    overflow: hidden;
}
.modalRegistrarGratis .detalle {
}
.modalRegistrarGratis .resumen {
    float: right;
    width: 350px;
    min-height: 500px;
    background: rgb(250, 250, 250);
    position: relative;
    padding-bottom: 80px;
}
.modalRegistrarGratis .resumen img {
    display: block;
    width: 100%;
    font-weight: bold;
    padding: 0 20px;
    margin: 30px 0 20px;
    line-height: 16px;
}
.modalRegistrarGratis .resumen .title {
    display: block;
    font-size: 16px;

    padding: 0 0px;
    margin: 0px 0 10px;
    line-height: 16px;
    font-weight: 500;
}
.modalRegistrarGratis .resumen .tickets-g {
    padding: 0 20px;
}
.modalRegistrarGratis .resumen .tickets-g > div {
    padding-right: 30px;
    color: var(--color-content);
    margin-bottom: 10px;
}
.modalRegistrarGratis .resumen .tickets-g > div span {
    float: left;
}
.modalRegistrarGratis .resumen .tickets-g > div span:last-child {
    float: right;
}
.modalRegistrarGratis .resumen .tickets-g .ticket {
    padding-right: 30px;
    position: relative;
    font-size: 16px;

    margin-bottom: 10px;
}
.modalRegistrarGratis .resumen .tickets-g .ticket p {
    float: left;
}
.modalRegistrarGratis .resumen .tickets-g .ticket p:nth-child(2) {
    float: right;
    text-align: center;
    min-width: 22px;
}
.modalRegistrarGratis .resumen .tickets-g .ticket i {
    position: absolute;
    cursor: pointer;
    right: 0px;
    top: 1px;
    color: var(--color-content);
}

.modalRegistrarGratis .resumen .tickets-g .total {
    padding-right: 30px;
    position: relative;
    font-size: 16px;

    font-weight: bold;
    padding-top: 20px;
    margin-top: 20px;
    border-top: solid 1px rgb(214, 214, 214);
    margin-bottom: 10px;
}
.modalRegistrarGratis .resumen .tickets-g .total span {
    float: left;
}
.modalRegistrarGratis .resumen .tickets-g .total span:nth-child(2) {
    float: right;
}
.modalRegistrarGratis .resumen #registrar-g {
    width: 100%;
    width: calc(100% - 40px);
    position: absolute;
    bottom: 15px;
    left: 20px;
    background: var(--bg-button-activo);
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: 50px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
}
.modalRegistrarGratis .detalle {
    padding: 40px;
    float: left;
    width: 100%;
    width: calc(100% - 350px);
}
.modalRegistrarGratis .detalle .event {
    position: relative;
    padding-right: 110px;
    padding-bottom: 30px;
    border-bottom: solid 1px rgb(244, 244, 244);
}
.modalRegistrarGratis .detalle .event .title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 15px;
    font-weight: bold;
}
.modalRegistrarGratis .detalle .event p {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
}
.modalRegistrarGratis .detalle .event .cant {
    width: 100px;
    position: absolute;
    bottom: 30px;
    right: 0;
}
.modalRegistrarGratis .detalle .event .cant .wrap-input {
    min-height: 50px;
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.16);
    border-radius: 5px;
    margin-bottom: 0;
}

.calendar-fix {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: -120%;
    left: 0;
    background: rgb(255, 255, 255);
    height: 90px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
    -webkit-transition: ease all 0.3s;
    -o-transition: ease all 0.3s;
    transition: ease all 0.3s;
}
.calendar-fix.show {
    top: 0;
    -webkit-transition: ease all 1s;
    -o-transition: ease all 1s;
    transition: ease all 1s;
}
.calendar-fix .wrap {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 100%;
    padding: 0 15px;
    padding-top: 1px;
    display: flex;
    justify-content: space-between;
}
.calendar-fix .wrap > span:first-child {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 580px;
    justify-content: space-between;
}
.calendar-fix .wrap > span:last-child {
    display: flex;
    align-items: center;
}
.calendar-fix .wrap .title {
    line-height: 16px;
    margin-top: 18px;
}
.calendar-fix .wrap button {
    width: 100%;
    max-width: 390px;
    line-height: 50px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    margin-top: 19px;
    -webkit-transition: linear all 0.3s;
    -o-transition: linear all 0.3s;
    transition: linear all 0.3s;
}
.wrap-evento .precio {
    display: flex !important;
    float: none !important;
    flex-direction: column !important;
    margin-right: 20px !important;
    text-align: right !important;
    margin-top: 40px !important;
    margin-bottom: 0 !important;
    align-items: flex-end !important;
}
.wrap-evento .precio span {
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 10px;
}
.wrap-evento .precio p {
    font-size: 20px;

    line-height: 20px;
}
.calendar-fix .calendar {
    max-width: 580px;
    width: 100%;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    background: rgb(255, 255, 255);
    margin-bottom: 10px;
    margin-top: 23px;
}
.calendar-fix .calendar article:first-child {
    border-top: none !important;
}
.calendar-fix .calendar.active {
    -webkit-transition: ease all 0.3s;
    -o-transition: ease all 0.3s;
    transition: ease all 0.3s;
}
.calendar-fix .calendar i.icon-calendar {
    position: absolute;
    font-size: 17px;
    left: 0px;
    top: 0px;
    pointer-events: none;
}
.calendar-fix .calendar i.icon-angle-d {
    position: absolute;
    font-size: 17px;
    right: 10px;
    top: 0px;
    pointer-events: none;
}
.calendar-fix .calendar i.icon-angle-d {
    font-size: 8px;
    top: 5px;
}
.calendar-fix .calendar .item.sel {
    font-size: 16px;
    font-weight: 600;
    padding-left: 30px;
}
.eventoUnico .calendar-fix .calendar .item.sel,
.eventoUnico .calendar-fix .calendar {
    padding-left: 0;
}
.calendar-fix .calendar .wrap-opciones.datepicker {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.calendar-fix .calendar .wrap-opciones {
    max-height: 350px;
    overflow-y: auto;
}

#modalShare {
    /* overflow: scroll; */
}
#modalShare .modal-content {
    margin: 20px 10px;
}

#modalShare .conten-share {
    position: relative;
}
#modalShare .conten-share i {
    cursor: pointer;
    margin-top: 20px;
    display: block;
    margin-right: 20px;
}
#modalShare .conten-share .content {
    max-width: 420px;
    margin: 60px auto;
    padding: 0 10px;
    margin-top: 20px;
}
#modalShare .conten-share .content .title {
    text-align: center;
    margin-bottom: 35px;
}
#modalShare .conten-share .content .redes {
    text-align: center;
    margin-bottom: 20px;
}
#modalShare .conten-share .content .redes > div {
    display: inline-block;
    margin: 0 5px 20px;
}
#modalShare .conten-share .content .link {
    max-width: 320px;
    margin: 0 auto;
    font-family: var(--font);
}
#modalShare .conten-share .content .link span {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: rgb(184, 191, 206);
    margin-bottom: 10px;
    cursor: pointer;
}
#modalShare .conten-share .content .link label {
    border-radius: 2px;
    background: rgb(242, 246, 255);
    color: rgb(0, 177, 255);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    padding-left: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
#modalShare .conten-share .content .link label button {
    background: var(--c1) !important;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 3px;
    color: white;
    float: right;
}
/****PAGINA Evento****/

/****EVENTO GRATIS****/
#modalGratis {
}
#modalGratis .modal-content {
    overflow: hidden;
}
#modalGratis .wrap-gratis {
    position: relative;
    padding-right: 300px;
}
#modalGratis .wrap-gratis .wrap-info {
    height: 600px;
}

#modalGratis .wrap-gratis .resumen figure img {
    display: block;
    max-width: 100%;
}
#modalGratis .wrap-gratis .resumen .wrap-resumen {
    padding: 30px 20px 0;
    height: 100%;
}
#modalGratis .wrap-gratis .resumen {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 300px;
    padding-bottom: 60px;
    background: rgb(250, 250, 250);
}
#modalGratis .wrap-gratis .resumen .wrap-resumen #orderG > p {
    margin-bottom: 10px;
}
#modalGratis .wrap-gratis .resumen .wrap-resumen #orderG > .tickets {
    margin-bottom: 10px;
}
#modalGratis .wrap-gratis .resumen .wrap-resumen #orderG .entradas {
    padding-right: 30px;
    border-bottom: solid 1px rgb(190, 190, 190);
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
}
#modalGratis .wrap-gratis .resumen .wrap-resumen #orderG .entradas > div {
    font-size: 12px;
    margin-bottom: 10px;
}
#modalGratis .wrap-gratis .resumen .wrap-resumen #orderG .entradas > article {
    position: relative;
}
#modalGratis .wrap-gratis .resumen .wrap-resumen #orderG .entradas > article i {
    position: absolute;
    right: -25px;
    bottom: 3px;
    cursor: pointer;
}
#modalGratis .wrap-gratis .resumen .wrap-resumen #orderG .total {
    padding-right: 30px;
}

#modalGratis .wrap-gratis .resumen #registrarG {
    position: absolute;
    bottom: 10px;
    width: 100%;
    max-width: 260px;
    line-height: 50px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 16px;
    border: none;
    -webkit-transition: linear all 0.3s;
    -o-transition: linear all 0.3s;
    transition: linear all 0.3s;
}
#modalGratis .wrap-gratis .wrap-info {
}
#modalGratis .wrap-gratis .wrap-info section {
    padding: 0 0px;
    display: none;
    max-height: 100%;
    height: 100%;
}
#modalGratis .wrap-gratis .wrap-info section.active {
    display: block;
}
#modalGratis .wrap-gratis .wrap-info section#entradasGratis i.icon-volver {
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 9;
    cursor: pointer;
}
#modalGratis .wrap-gratis .wrap-info section .evento {
    padding: 30px;
    position: relative;
    border-bottom: solid 1px rgb(244, 244, 244);
    text-align: center;
}
#modalGratis .wrap-gratis .wrap-info section .evento > div {
    font-size: 16px;
    font-weight: bold;
}
#modalGratis .wrap-gratis .wrap-info section .evento > p {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
}

#modalGratis .wrap-gratis .wrap-info section .entrada {
}
#modalGratis .wrap-gratis .wrap-info section .entrada p {
    font-size: 16px;
    font-weight: 300;
    line-height: 50px;
}
#modalGratis .wrap-gratis .wrap-info section .entrada > div {
    padding: 30px;
    position: relative;
    border-bottom: solid 1px rgb(244, 244, 244);
}
#modalGratis
    .wrap-gratis
    .wrap-info
    section
    .entrada
    > div
    > div.select-gratis {
    position: absolute;
    top: 50%;
    right: 30px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
#modalGratis
    .wrap-gratis
    .wrap-info
    section
    .entrada
    > div
    > div.select-gratis
    select {
    width: 100px;
    line-height: 50px;
    height: 50px;
    padding: 0 30px 0 20px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    background: rgb(255, 255, 255);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    -webkit-appearance: none !important;
}
#modalGratis
    .wrap-gratis
    .wrap-info
    section
    .entrada
    > div
    > div.select-gratis
    i {
    position: absolute;
    right: 2px;
    top: 2px;
    width: 30px;
    pointer-events: none;
    height: 46px;
    line-height: 48px;
    border-radius: 0 5px 5px 0;
    display: block;
    background: rgb(255, 255, 255);
    font-size: 10px;
}
#modalGratis .wrap-gratis .wrap-info section#registrarGratis {
}
#modalGratis .wrap-gratis .wrap-info section#registrarGratis .top {
    padding: 10px 30px 30px;
    text-align: center;
    border-bottom: solid 1px rgb(244, 244, 244);
    position: relative;
}
#modalGratis .wrap-gratis .wrap-info section#registrarGratis .top > i {
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
}
#modalGratis .wrap-gratis .wrap-info section#registrarGratis .datos {
    overflow: auto;
    max-height: 600px;
    max-height: calc(100% - 100px);
}
#modalGratis .wrap-gratis .wrap-info section#registrarGratis .datos .wrap {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
}
#modalGratis
    .wrap-gratis
    .wrap-info
    section#registrarGratis
    .datos
    .wrap
    .nota {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
}
#modalGratis
    .wrap-gratis
    .wrap-info
    section#registrarGratis
    .datos
    .wrap
    .title-d {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}
#modalGratis
    .wrap-gratis
    .wrap-info
    section#registrarGratis
    .datos
    .wrap
    .wrap-input.select
    i.icon-angle-d {
    top: 22px;
}
/****EVENTO GRATIS****/

/****Merchandising****/
.wrap-merch {
    margin-top: 30px;
    margin-bottom: 30px;
}
.wrap-merch .top-merchs {
}
.wrap-merch .top-merchs > div {
}
.wrap-merch .top-merchs > p {
    line-height: 25px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.wrap-merch .top-merchs > div span {
    line-height: 25px;
    padding: 0 10px;
    border-radius: 20px;
    border: solid 1px rgb(112, 112, 112);
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin: 0 5px 10px;
    cursor: pointer;
}
.wrap-merch .top-merchs > div span:hover,
.wrap-merch .top-merchs > div span.active {
    color: rgb(255, 255, 255);
    background: rgb(255, 157, 0);
    border: solid 1px rgb(255, 157, 0);
}
.wrap-merch .cats-merch {
    margin-top: 20px;
    margin-bottom: 30px;
}
.wrap-merch .cats-merch > span {
    display: inline-block;
    margin: 0 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    cursor: pointer;
}
.wrap-merch .cats-merch > span:hover,
.wrap-merch .cats-merch > span.active {
    color: rgb(255, 157, 0);
}
.wrap-merch .productos {
}
.wrap-merch .productos .itemprod {
    max-width: 212px;
    margin: 0 auto 30px;
    border-radius: 3px;
    border: solid 1px rgb(214, 214, 214);
}
.wrap-merch .productos .itemprod > figure {
    overflow: hidden;
    cursor: pointer;
}
.wrap-merch .productos .itemprod > figure img {
    display: block;
    width: 100%;
    -webkit-transition: ease-in-out 0.3s all;
    -o-transition: ease-in-out 0.3s all;
    transition: ease-in-out 0.3s all;
}
.wrap-merch .productos .itemprod > figure:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.wrap-merch .productos .itemprod .desc {
    position: relative;
    padding: 10px;
}
.wrap-merch .productos .itemprod .desc p {
    font-size: 12px;
    font-weight: 300;
}
.wrap-merch .productos .itemprod .desc .colores {
    padding-right: 70px;
    margin-top: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.wrap-merch .productos .itemprod .desc .colores-m {
    display: none;
}

.wrap-merch .productos .itemprod .desc .colores span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 3px;
    margin-bottom: 5px;
    border-radius: 2px;
    cursor: pointer;
}
.wrap-merch .productos .itemprod .desc .colores span:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.wrap-merch .productos .itemprod .desc .precio {
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    right: 10px;
    bottom: 15px;
}

#checkout-agg {
    margin-top: 40px;
    width: 100%;
    display: block;
    color: rgb(255, 255, 255);
    line-height: 50px;
    border: none;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
}

#modalProd .modal-content {
}
#modalProd .modal-content {
    padding: 50px 20px 40px;
    position: relative;
}
#modalProd .modal-content i.icon-cerrar-simple {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
#modalProd .modal-content .detalleProd {
}
#modalProd .modal-content .detalleProd .images {
    float: left;
    width: 50%;
}
#modalProd .modal-content .detalleProd .images .slider {
    position: relative;
    padding-left: 80px;
    padding-right: 10px;
    opacity: 0;
}
#modalProd .modal-content .detalleProd .images .slider > div {
}
#modalProd .modal-content .detalleProd .images .slider > div:last-child {
    position: absolute;
    width: 60px;
    left: 0;
    top: 0;
}
#modalProd .modal-content .detalleProd .images .slider > div:last-child img {
    max-width: 100%;
    margin-bottom: 20px;
}
#modalProd .modal-content .detalleProd .images .slider .bx-wrapper {
    position: relative;
    margin-bottom: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
}
#modalProd .modal-content .detalleProd .images .slider .bx-wrapper img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
#modalProd .modal-content .detalleProd .images .slider .bx-wrapper .bx-prev {
    background: url(./../../images//merch/left.svg) no-repeat;
}
#modalProd .modal-content .detalleProd .images .slider .bx-wrapper .bx-next {
    background: url(./../../images//merch/right.svg) no-repeat;
}

#modalProd .modal-content .detalleProd .info {
    float: left;
    width: 50%;
    position: relative;
    min-height: 100%;
}
#modalProd .modal-content .detalleProd .info .nombre {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 25px;
}

#modalProd .modal-content .detalleProd .info .subt {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 15px;
}

#modalProd .modal-content .detalleProd .info .colores {
    margin-bottom: 25px;
}
#modalProd .modal-content .detalleProd .info .colores span {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
}
#modalProd .modal-content .detalleProd .info .colores span.active:after {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    top: -3px;
    left: -3px;
    border: solid 1px rgb(255, 157, 0);
}
#modalProd .modal-content .detalleProd .info .colores span:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}

#modalProd .modal-content .detalleProd .info .tallas {
    margin-bottom: 25px;
}
#modalProd .modal-content .detalleProd .info .tallas span {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    margin-right: 5px;
    margin-bottom: 5px;
    border: solid 1px rgb(0, 45, 90);
    cursor: pointer;
}
#modalProd .modal-content .detalleProd .info .tallas span.active,
#modalProd .modal-content .detalleProd .info .tallas span:hover {
    background: rgb(255, 157, 0);
    border: solid 1px rgb(255, 157, 0);
    color: rgb(255, 255, 255);
}
#modalProd .modal-content .detalleProd .info .tallas a {
    text-decoration: underline;
}
#modalProd .modal-content .detalleProd .info .cantidad {
    max-width: 140px;
}
#modalProd .modal-content .detalleProd .info .cantidad .wrap-input {
    min-height: 10px;
}
#modalProd .modal-content .detalleProd .info #guiatalla {
    margin-bottom: 25px;
    display: none;
}
#modalProd .modal-content .detalleProd .info #guiatalla img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
#modalProd .modal-content .detalleProd .info .price {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 25px;
}
#modalProd .modal-content .detalleProd .info .optiondel {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
}
#modalProd .modal-content .detalleProd .info .optiondel label {
    height: 12px;
    position: absolute;
    width: 12px;
    border-radius: 50%;
    left: 0;
    top: 4px;
    overflow: hidden;
    cursor: pointer;
}
#modalProd .modal-content .detalleProd .info .optiondel label span {
    height: 12px;
    display: block;
    top: 0px;
    left: 0px;
    position: absolute;
    width: 12px;
    border-radius: 50%;
    border: solid 1px rgb(0, 0, 0);
    cursor: pointer;
    background: rgb(255, 255, 255);
    z-index: 99;
}
#modalProd
    .modal-content
    .detalleProd
    .info
    .optiondel
    label
    span.active:after {
    content: "";
    display: block;
    position: absolute;
    height: 6px;
    width: 6px;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background: rgb(255, 157, 0);
    cursor: pointer;
    z-index: 9999;
}
#modalProd .modal-content .detalleProd .info .optiondel input {
    position: absolute;
    left: -50px;
    opacity: 0;
}
#modalProd .modal-content .detalleProd .info .optiondel > span {
    font-size: 12px;
    line-height: 18px;
}
#modalProd .modal-content .detalleProd .info button {
    margin-top: 60px;
    width: 100%;
    display: block;
    color: rgb(255, 255, 255);
    line-height: 50px;
    border: none;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
}
/****Merchandising****/

/****FOOTER****/
footer {
    background-color: var(--c4) !important;
}
footer .info {
    float: left;
}
footer .info .dir {
    float: left;
    max-width: 450px;
    font-size: 14px;
    font-weight: 300;
    margin-top: 3px;
    padding-left: 15px;
    border-left: solid 1px rgba(255, 255, 255, 0.5);
}
footer .info .dir span {
    display: block;
    color: white;
}
footer .info .redes {
    float: left;
    margin-top: 0px;
}
footer .info .redes a {
    display: inline-block;
    color: #fff;
    margin-right: 25px;
}
footer .info .redes a:last-child {
    margin-right: 15px;
}
footer .dev {
    float: right;
    opacity: 0.6;
    text-align: right;
}
footer .dev p {
    font-size: 14px;
    text-align: right;
    line-height: 14px;
    margin-bottom: 5px;
    font-family: var(--font);
    color: var(--c2);
}
#footer-m {
    display: none;
}
body.f-min {
    padding-bottom: 60px;
}
body.f-min footer {
    min-height: 60px;
    height: 60px;
}
body.f-min footer .info {
    display: none;
}
body.f-min footer .dev {
    margin-top: 7px;
}
body.f-min footer .dev p {
    margin-bottom: 5px;
    vertical-align: top;
    line-height: 16px;
    margin-top: 3px;
    font-family: var(--font);
    margin-bottom: 0;
}
body.f-min footer .dev img {
    margin-top: 0px;
}
body.f-min #wrap-ppal {
    padding-bottom: 90px;
}

footer .wrap {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1080px !important;
}
footer .wrap article {
    flex: 1;
}

footer .wrap article:nth-child(2) {
    flex: 3;
}

footer .logo {
    display: block;
    float: left;
    max-width: 140px;
}
/****FOOTER****/

/****INPUTS****/
.wrap-input {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    min-height: 67px;
}
.wrap-input .intl-tel-input {
    display: block;
}
.wrap-input label {
    font-family: var(--font);
    font-size: 13px;
    font-weight: 500;
    color: var(--c4);
    line-height: 12px;
    margin-bottom: 10px;
}
.wrap-input input {
    line-height: 38px;
    height: 40px;
    color: rgb(53, 59, 71);
    font-size: 14px;
    font-weight: 400;
    border: solid 1px #e4e8ed;
    border-radius: 6px;
    width: 100%;
    padding: 0 10px;
    display: block;
    box-shadow: none !important;
    -webkit-appearance: none !important;
    outline: none !important;
}
.wrap-input input[type="text"],
.wrap-input input[type="password"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.wrap-input.input-tel-flag input[type="text"] {
    padding-left: 65px;
}
.wrap-input.input-tel-flag span {
    position: absolute;
    left: 10px;
    top: 36px;
}

.wrap-input input[type="radio"],
.wrap-input input[type="checkbox"] {
    display: inline-block;
    width: 20px;
    height: 20px;
    box-shadow: none !important;
}
.wrap-input select {
    display: block;
    width: 100%;
    padding: 0 10px;
    line-height: 40px;
    border: solid 1px rgb(221, 221, 221);
    height: 40px;
    color: rgb(53, 59, 71);
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    box-shadow: none !important;
    -webkit-appearance: none !important;
    border-radius: 2px;
    outline: none;
}
.wrap-input select + i.icon-angle-d {
    position: absolute;
    height: 40px;
    display: block;
    width: 20px;
    right: 0px;
    bottom: 0px;
    pointer-events: none;
    font-size: 6px;
    line-height: 38px;
    color: rgb(144, 144, 144);
    border-radius: 0 1px 1px 0;
    background: none;
}
.wrap-input input:focus {
}
.wrap-input label.inputFocus {
    font-weight: 300;
    color: rgb(210, 210, 210);
}
.wrap-input.visa input,
.wrap-input.cvv input,
.wrap-input.amex input,
.wrap-input.master input {
    padding-right: 40px;
}
.wrap-input.visa:after {
    position: absolute;
    content: "";
    width: 21px;
    height: 16px;
    display: block;
    background: url(./../../images//pago/visa.svg) no-repeat 100% 100%;
    bottom: 11px;
    right: 10px;
}

.wrap-input.master:after {
    position: absolute;
    content: "";
    width: 21px;
    height: 16px;
    display: block;
    background: url(./../../images//pago/master.svg) no-repeat 100% 100%;
    bottom: 11px;
    right: 10px;
}

.wrap-input.cvv:after {
    position: absolute;
    content: "";
    width: 21px;
    height: 15px;
    display: block;
    background: url(./../../images//pago/cvv.svg) no-repeat 100% 100%;
    bottom: 12px;
    right: 10px;
}

.wrap-input.amex:after {
    position: absolute;
    content: "";
    width: 21px;
    height: 16px;
    display: block;
    background: url(./../../images//pago/amex.svg) no-repeat 100% 100%;
    bottom: 11px;
    right: 10px;
}
/****INPUTS****/

/****loading****/
@keyframes lds-ellipsis3 {
    0%,
    25% {
        left: 32px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        left: 32px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    75% {
        left: 100px;
    }
    100% {
        left: 168px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes lds-ellipsis3 {
    0%,
    25% {
        left: 32px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        left: 32px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    75% {
        left: 100px;
    }
    100% {
        left: 168px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    25%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}
@-webkit-keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    25%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}
@keyframes lds-ellipsis {
    0% {
        left: 32px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    25% {
        left: 32px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        left: 100px;
    }
    75% {
        left: 168px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        left: 168px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}
@-webkit-keyframes lds-ellipsis {
    0% {
        left: 32px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    25% {
        left: 32px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        left: 100px;
    }
    75% {
        left: 168px;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        left: 168px;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}
.lds-ellipsis {
    position: relative;
}
.lds-ellipsis > div {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
}
.lds-ellipsis div > div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f00;
    position: absolute;
    top: 100px;
    left: 32px;
    -webkit-animation: lds-ellipsis 1s cubic-bezier(0, 0.5, 0.5, 1) infinite
        forwards;
    animation: lds-ellipsis 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}
.lds-ellipsis div:nth-child(1) div {
    -webkit-animation: lds-ellipsis2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite
        forwards;
    animation: lds-ellipsis2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    background: #d0ecff;
}
.lds-ellipsis div:nth-child(2) div {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
    background: #1384b5;
}
.lds-ellipsis div:nth-child(3) div {
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
    background: #164459;
}
.lds-ellipsis div:nth-child(4) div {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    background: #00b1ff;
}
.lds-ellipsis div:nth-child(5) div {
    -webkit-animation: lds-ellipsis3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite
        forwards;
    animation: lds-ellipsis3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
    background: #d0ecff;
}
.lds-ellipsis {
    width: 40px !important;
    height: 40px !important;
    -webkit-transform: translate(-20px, -20px) scale(0.2) translate(20px, 20px);
    transform: translate(-20px, -20px) scale(0.2) translate(20px, 20px);
}
/****loading****/
@keyframes luka-loading {
    0% {
        -webkit-transform: translate(0%, 0%) rotate(0deg);
        transform: translate(0%, 0%) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(0%, 0%) rotate(360deg);
        transform: translate(0%, 0%) rotate(360deg);
    }
}

.loading {
    position: relative;
    display: flex;
    justify-content: center;
}

.loading div,
.loading div:after {
    position: relative;
    width: 25px;
    height: 25px;
    border: 3px solid #00ccff;
    border-top-color: transparent;
    border-radius: 50%;
}

.loading div {
    -webkit-animation: luka-loading 1s linear infinite;
    animation: luka-loading 1s linear infinite;
    display: inline-flex;
}

.loading div:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.loading.inside div,
.loading.inside div:after {
    width: 23px;
    height: 23px;
    border: 3px solid white;
    border-top-color: transparent;
}

/*********/
select:invalid {
    color: red !important;
}
/* .wrap-input select:first-child {
  background: red;
} */
/****RESPONSIVE LAYOUT****/
@media (min-width: 1680px) {
    .container {
        width: 1536px !important;
        margin: 0 auto;
        padding: 0 0px !important;
    }
}
@media (min-width: 576px) {
    .container {
        width: 550px;
        margin: 0 auto;
        padding: 0 15px;
    }
}
.webpay-hidden-iframe {
    display: none;
}
/****PARPADEO TIMER***/
.parpadea {
    color: red !important;
    animation-name: parpadeo;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: parpadeo;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes parpadeo {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes parpadeo {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
        margin: 0 auto;
        padding: 0 15px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
        margin: 0 auto;
        padding: 0 15px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
        margin: 0 auto;
        padding: 0 15px;
    }
    .modal-lg {
        width: 1080px;
    }
}

@media (min-width: 1360px) {
    .container {
        width: 1350px;
        margin: 0 auto;
        padding: 0 15px;
    }
}

@media (max-height: 800px) {
}

/*CUSTOM BOOTSTRAP*/
.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto !important;
    margin-right: auto !important;
}
.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: "";
}
.modal-content {
    width: 100%;
}
.modal-backdrop {
    z-index: 9999;
}
.modal {
    z-index: 99999;
}
@media (max-width: 576px) {
    .modal-dialog-centered {
        min-height: calc(100% - 60px);
        padding: 0 15px;
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 60px);
    }
    .logo-conf-top {
        display: none !important;
    }
}
.icon-share-2 {
    cursor: pointer;
    margin-top: 7px;
    display: inline-block;
    color: var(--c4) !important;
    fill: var(--c4) !important;
    stroke: var(--c4) !important;
}
.icon-share-2 svg {
    stroke: var(--c4) !important;
    color: var(--c4) !important;
    fill: var(--c4) !important;
}

@media (min-width: 1100px) {
    .icon-share-2 svg {
        width: 21px !important;
    }
}

.fee-included {
    display: inline-block;
}

.fee-included label:hover {
    cursor: pointer;
}

.fee-included label {
    text-decoration: underline;
}

.tooltip-fee {
    opacity: 0;
    transition: 0.5s;
}

.fee-included:hover .tooltip-fee {
    opacity: 1;
}

.tooltip-fee {
    position: absolute;
    background: white;
    top: 26px;
    right: 0;
    border-radius: 3px;
    padding: 0px 10px 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 270px;
}

.tooltip-container {
    width: auto;
    height: auto;
    font-weight: normal;
}

.tooltip-fee:before {
    content: "";
    background: white;
    width: 10px;
    height: 10px;
    position: relative;
    top: -4px;
    display: block;
    right: -185px;
    transform: rotate(45deg);
}

.tooltip-content span {
    flex: 4;
    font-size: 11px;
    padding: 0 5px;
    text-align: left;
}

.tooltip-content span:first-child {
    flex: 6;
}

.tooltip-header span {
    text-align: left;
    flex: 4;
    padding: 3px 5px;
    color: rgb(10, 10, 10);
}

.tooltip-header span:first-child {
    flex: 6;
}
.luka {
    font-family: var(--font) !important;
}
#luka-container {
    justify-content: space-between;
    margin-bottom: 30px;
}
#luka-container #Custom-select-pais input {
    border-radius: 6px;
}
#luka-container ._form-control {
    border: 1px solid #e4e8ed !important;
    border-radius: 6px;
}
.luka ._form-group label {
    font-weight: 400 !important;
    color: rgb(153, 153, 153);
}
.luka-terminos label span {
}
.luka .luka-wrapForm {
    font-family: "Open-sans" !important;
}
.luka-paypal,
.luka ._form-group label {
    font-size: 13px !important;
}

.react-tel-input .form-control {
    width: 100%;
    border: solid 1px #e4e8ed;
    border-radius: 6px;
    line-height: 38px;
    height: 40px;
}
.react-tel-input .selected-flag {
    width: 50px !important;
    visibility: initial;
    justify-content: center;
}
.react-tel-input .selected-flag .flag {
    visibility: initial;
}
.react-tel-input .flag-dropdown {
    border-color: #e4e8ed;
    border-radius: 6px 0 0 6px;
}
.skel-header {
    height: 120px;
    max-height: 120px;
    padding-top: 7px;
    z-index: 2;
}
.skel-calendar {
    width: 60%;
}

.skel-pad {
    padding: 30px;
    padding-left: 0;
    padding-bottom: 0;
}

.skel-flex {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 230px;
}

@media (max-width: 1200px) {
    .skel-flex {
        margin-bottom: 140px;
    }
    .skel-flex2 {
        margin-bottom: 30px;
    }
    .skel-pad {
        padding-left: 30px;
    }
    .col2s {
        display: none;
    }
    .header-skel {
        height: 80px !important;
    }
}

.skel-flex2 {
    display: flex;
    justify-content: flex-end;
}
.header-skel {
    width: 100%;
    height: 110px;
    background: white;
}

.skel-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1100;
    background-color: #ffffff;
}

.skel-main {
    box-shadow: none !important;
    background: #fafafa;
}
.mainE {
    min-height: 100%;
    position: relative;
    background: #fafafa;
    font-family: var(--font) !important;
}
.skel-img span span {
    line-height: 2 !important;
    background: #ebebeb;
    background-image: linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb);
    background-size: 200px 100%;
    background-repeat: no-repeat;
}

.skel-row {
    border: solid 1px #f2f5fa;
    border-left: none;
    border-right: none;
    padding: 30px;
    margin-left: 0px;
}

.skel-row2 {
    padding: 30px;
}

.col-skel {
    padding: 30px;
}

.box-skel {
    margin-bottom: 60px;
}
.wrap-phone{
    position: relative;
}
.wrap-phone{
    position: relative;
}
.wrap-phone .form-control {
    position: relative;
    padding: 0 10px 0 60px !important;
}

/*
#usa-input-phone .react-tel-input .flag-dropdown {
    display: none;
}*/

#usa-input-phone .react-tel-input .form-control {
    padding-left: 10px !important;
}

.size40 {
    font-size: 40px;
}
.icon-active-color::before {
    color: var(--c1) !important;
}

.ui-widget-header .ui-icon {
    background-image: none !important;
}

.ui-icon,
.ui-widget-content .ui-icon {
    background-image: none !important;
}

.ui-datepicker-next:after {
    content: " ";
    width: 12px;
    height: 12px;
    background: url("/images/direccional.svg") no-repeat;
    top: 2px;
    right: 3px;
    position: absolute;
    display: inline-block;
}

.ui-datepicker-prev:before {
    content: " ";
    width: 12px;
    height: 12px;
    background: url("/images/flechas.svg") no-repeat;
    top: 4px;
    left: 3px;
    position: absolute;
    display: inline-block;
}

.ui-datepicker-next-hover {
    background: none !important;
    border: none !important;
}

.ui-datepicker-prev-hover {
    background: none !important;
    border: none !important;
}

.calendar .wrap-opciones.datepicker .ui-datepicker td span,
.ui-datepicker td a {
    font-size: 12px !important;
}

.ui-datepicker-month {
    color: var(--c1);
}

.ui-datepicker-calendar tbody tr {
    /* height: 30px; */
}

.wrap-ended {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
}

.content-timedout-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
}

.content-timedout-one > div {
    width: 100%;
}

.image-event-timed img {
    width: 100%;
}

.timed-out-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;
    right: 30px;
    background: #fff;
    border-radius: 8px;
    max-width: 300px;
    padding: 10px;
    font-weight: 600;
}

.timed-out-text figure {
    margin-right: 20px;
}
.timed-out-text figure img {
    width: 34px;
}

.content-timedout-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    width: 100%;
}

.content-timedout-two .text-form-timeout{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 30px;
}
.content-timedout-two .text-form-timeout svg {
    margin-right: 20px;
}
.content-timedout-two .text-form-timeout span{}


.content-timedout-two > div {
    width: 100%;
}
.content-timedout-two .form-timeout{
    display: flex;
    align-items: flex-end;
}
.content-timedout-two .form-timeout>div{
    display: flex;
    width: 66.666%;
}
.content-timedout-two .form-timeout button{
    margin-bottom: 20px;
    height: 45px;
    line-height: 45px;
    width: 33.333%;
}
.content-timedout-two .form-timeout>div>div{
    padding-right: 10px;
    width: 50%;
}
.content-timedout-two .form-timeout input{
    height: 45px; 
    line-height: 45px;
}

.notified-message{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    align-items: center;
}
.notified-message svg{}
.notified-message h4{
    display: block;
    max-width: 420px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.notified-message a{
    display: block;
    max-width: 320px;
}

@media (max-width: 768px) {
    .content-timedout-two .form-timeout{
        flex-direction: column;
    }
    .content-timedout-two .form-timeout>div{
        width: 100%;
        flex-direction: column;
    }
    .content-timedout-two .form-timeout>div>div{
        padding-right: 0px;
        width: 100%;
        margin-bottom: 10px;
    }
    .content-timedout-two .form-timeout button{
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 576px){
    .wrap-ended{
        margin-top: 40px;
    }
}


@media (max-width: 576px){
    .responsive_back_shop{
        display: none !important;
    }
}
#mapa-boletos-wrap .wrap-boletos .boletos .content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

#mapa-boletos-wrap .wrap-boletos .boletos .content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.d-flex {
    display: flex;
}
.flex-grow-1 {
    flex-grow: 1;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-end {
    justify-content: flex-end;
}
.align-items-center {
    align-items: center;
}
.logo-ext {
    margin: 0 40px;
    align-self: center;
    max-width: 200px;
}
.logo-ext img {
    /* width: 100%; */
    max-width: 200px;
    max-height: 70px;
}
.h-100 {
    height: 100%;
}

/*donaciones*/
.description-banner {
    display: flex;
}
.btn-donation,
.btn-donation:hover,
.btn-donation:focus {
    max-width: 130px;
    margin-bottom: 30px;
    height: 35px !important;
    font-size: 14px;
    line-height: unset;
}
.btn-donation.btn-ppal.active {
    background-color: var(--c4) !important;
}

.btn-donation-ppal,
.btn-donation-ppal:hover,
.btn-donation-ppal:focus {
    font-size: 14px;
    height: 40px !important;
    line-height: unset;
}
.grid-amount-donations {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}
.grid-amount-donations .btn-donation {
    margin-right: 5px;
}
/* .grid-amount-donations::after {
  content: '';
  display: block;
  margin-left: 130px;
} */
.titulo-donation {
    display: flex;
    justify-content: center;
    padding: 32px 0 0px;
    font-size: 40px;
    font-weight: bold;
}
@media (max-width: 768px) {
    .description-banner {
        flex-direction: column;
    }
}
@media (max-width: 992px) {
    .container-other-donation {
        margin-bottom: 30px;
    }
    body#evento #main .wrap-evento .banner-evento #fecha-r > div {
        display: none !important;
    }
}
.description-banner .wrap-input {
    height: 35px;
    min-height: 35px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mt-30 {
    margin-top: 30px;
}
.pr-30 {
    padding-right: 10px;
}
.container-other-donation {
    display: flex;
    flex-grow: 2;
    height: 35px;
    align-items: flex-end;
    justify-content: space-between;
}
.container-other-donation span {
    display: flex;
    flex: 1;
    justify-content: space-around;
}
.container-other-donation input {
    outline: none;
    border: none;
    border-bottom: 1px solid #909090;
    text-align: right;
    padding: 0 5px;
    padding-left: 30px;
}
.container-other-donation div {
    position: relative;
}
.container-other-donation div span {
    position: absolute;
    padding-left: 5px;
}
.tm-border {
  border: 1px solid var(--c4) !important;
}
.wrap-donation {
    margin-top: 0px !important;
    border-top: 1px solid #f5f5f5 !important;
}
.resumen-donation {
    top: initial !important;
}
.titulo-donacion {
    margin: 25px auto 15px;
    font-weight: bold;
}
.wrap-pago.donation {
    max-width: 100%;
    margin: 0 auto 20px;
}
.wrap-pago.donation .content,
.wrap-pago.donation .btn-pago {
    max-width: 790px !important;
}
.wrap-pago.donation .resumen {
    width: 280px;
}
.wrap-evento.confirmacion-donacion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.size-32 {
    font-size: 32px;
}
.size-18 {
    font-size: 18px;
}
.size-25 {
    font-size: 25px;
}
.confirmacion-donacion div {
    text-align: center;
}
.confirmacion-donacion div.title {
    line-height: 32px;
}
.confirmacion-donacion .btn-get-tk {
    display: block !important;
}
.pointer {
    cursor: pointer;
}
.resumen-donation .sub-title-bold {
    line-height: 25px !important;
}
.luka-tipo-pago figure {
    flex-wrap: wrap !important;
    justify-content: center !important;
}

#mainWrap {
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 5px;
}

#wrapconf {
    background: #fff;
    margin-top: 90px;
    min-height: 600px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#wrapconf img {
    display: block;
    margin: 50px auto;
}
#wrapconf p {
    text-align: center;
    max-width: 332px;
    color: #34444d;
    font-size: 20px;
    line-height: 26px;
    margin: 0 auto 10px;
}
#wrapconf h3 {
    color: #34444d;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

#wrapconf .datos {
    width: 100%;
    max-width: 332px;
    margin: 0 auto;
}
#wrapconf .datos p {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
}
#wrapconf .datos p span:nth-child(1) {
    font-weight: bold;
}
#wrapconf .datos p span {
}

.wrap-cedula {
    position: relative;
}
.wrap-cedula select {
    border: none;
    border-radius: 0;
    border-right: solid 1px rgb(221, 221, 221);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: none;
    width: 50px;
}
.wrap-cedula i {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
}
.wrap-cedula input {
    position: relative;
    padding-left: 60px;
}

.align_left_banner_donation {
    width: 100%;
    display: inline-block;
    text-align: left;
}
.align_left_banner_donation img {
    width: 100%;
}
.va_middle {
    align-items: center;
    display: flex !important;
}

.mt_app_donation {
    margin-top: 10px;
    display: inline-block;
}
.nro_ord {
    text-align: left;
    margin-bottom: 10px;
}

.link_turismo {
    display: inline-block;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(44, 55, 75, 0.1);
    line-height: 50px;
    font-weight: bold;
    background: var(--c1);
    color: white !important;
    margin-bottom: 20px;
    text-align: center;
}
@media (max-width: 600px) {
    #wrap-pago-realizado
        .wrap-orden
        .datos-o
        > div:last-child
        .d-item
        .news-letter {
        position: relative !important;
    }
    .link_turismo {
        width: 100%;
    }
    .va_middle {
        flex-direction: column;
    }
}
.cont_link_turismo {
    width: 100%;
    text-align: left;
}

.wrap_succes_payment {
    position: relative;
}

.wrap_succes_payment .white_cont {
    border-radius: 6px;
    border: 1.5px solid #ededed;
    background: #fff;
    padding: 30px;
    font-family: var(--font);
    color: #000;
    font-size: 16px;
    line-height: 30px;
}
.wrap_succes_payment .white_cont .linkes{
    line-height: 19px;
    color: #000;
}
.wrap_succes_payment .white_cont .linkes svg{
  display: inline-flex;
}
.wrap_succes_payment .white_cont .linkes:hover{
    text-decoration: underline;
}
.wrap_succes_payment .white_cont .title_pay {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin: 0;
}
.wrap_succes_payment .resumen {
    position: relative;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    top: 0 !important;
}

.wrap_succes_payment .white_cont .ul_tickets {
    display: flex;
    gap: 10px;
    list-style: none;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}
.wrap_succes_payment .white_cont .ul_tickets li {
    color: #707070;
    width: 80%;
    font-size: 14px;
    line-height: 26px;
    display: inline-flex;
    border-radius: 6px;
    background: #F6F7FB;
    flex-direction: row;
    padding: 15px 20px;
    align-items: center;
    gap: 10px;
    justify-content: space-around;
}
.wrap_succes_payment .white_cont .ul_ {
    list-style: inside;
}

.imag_pay {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 7px;
}
.wrap_succes_payment .white_cont li {
    font-weight: 400;
    flex-direction: column;
    font-size: 16px;
    line-height: 30px;
}
.wrap_succes_payment .white_cont label {
    color: #000;
    line-height: 18px;
    font-size: 16px;
    font-weight: 400;
}
.sp_bg {
    min-height: 100vh;
    /*font-family: 'Open Sans' !important;background: var(--c2);*/
}
.payment_succes_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--c2);
    padding:26px 0;
}
.payment_succes_header .text_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
}
.payment_succes_header  .title-h{
    margin: 0 !important;
    font-size: 40px !important;
    color: var(--c4);
    text-align: center;
}
/*Rededign*/

body#evento #main .wrap-evento {
    margin-bottom: 0;
    padding-bottom: 40px;
}
.banner-evento .swiper-button-prev,
.banner-evento .swiper-button-next {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    height: 70px;
    margin-top: -34px;
}
.banner-evento .swiper-button-prev::after,
.banner-evento .swiper-button-next::after {
    font-size: 30px;
    color: #fff;
}
.wrap-evento {
    justify-content: space-between;
}
.wrap-evento .column-left {
    overflow: hidden;
    min-width: 100%;
}
.wrap-evento .column-right {
    border-radius: 8px;
    width: 100%;
}

@media (min-width: 1280px) {
    .wrap-evento {
        justify-content: space-between;
    }
    .wrap-evento .column-left {
        overflow: hidden;
        width: 100%;
        min-width: 100%;
        width: calc(100% - 430px);
        min-width: calc(100% - 430px);
    }
    .wrap-evento .column-right {
        border-radius: 8px;
        width: 400px;
    }
}

.wrap-evento .column-left .banner-evento,
.wrap-evento .column-left .banner-donacion {
    position: relative;
}
.wrap-evento .entradas-precios {
    padding: 30px 20px;
    background: #fff;
    border-radius: 8px;
    font-size: 16px;
    min-height: 505px;
    border: solid 1px #e4e8ed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrap-evento .column-left .notas {
    display: none;
}
.wrap-evento .column-left .entradas-precios,
.column-left .info-importante  {
    display: none;
}
.wrap-evento .column-right .place span {
    font-weight: 600;
    font-size: 18px;
}
.wrap-evento .column-left .notas,
.wrap-evento .column-right .notas {
    border-radius: 8px;
    padding: 30px 20px;
    background: #fff;
    margin-top: 30px;
}
.wrap-evento .column-right .notas {
    border: solid 1px #e4e8ed;
    color: var(--c5)
}

.wrap-evento .notas h5 {
    margin-top: 0 !important;
    color: var(--c1);
}
.wrap-evento .notas p {
    font-size: 16px;
    line-height: 26px;
}
.wrap-evento .notas ul, .wrap-evento .notas ol {
    padding-left: 15px;
    list-style: auto;
}
.wrap-evento .notas ul li, .wrap-evento .notas ol li{
    margin-bottom: 10px;
}

@media (max-width: 1280px) {
    .wrap-evento .column-left .notas,
    .column-left .info-importante {
        display: block;
    }
    .wrap-evento .column-left .entradas-precios {
        display: block;
        margin: 40px 0;
    }
    .wrap-evento .column-right .notas,
    .column-right .info-importante,
    .wrap-evento .column-right .entradas-precios {
        display: none;
    }
    .calendar-fix .wrap > span:first-child {
        max-width: 100%;
    }
    .calendar-fix .calendar {
        max-width: 100% !important;
    }

    body#evento #main .wrap-evento .banner-evento .info-evento .title {
        font-size: 24px;
    }
    body#evento #main .wrap-evento .banner-evento {
        padding-bottom: 30px !important;
    }

    body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r {
        top: 10px;
    }

    body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r span {
        font-size: 24px;
    }
    body#evento #main .wrap-evento .banner-evento .info-evento #fecha-r p {
        font-size: 26px;
    }
}
@media (max-width: 640px) {
    .entradas-precios .linkto {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
    }
}

.wrap-evento .entradas-precios .button-book {
    margin-top: 40px;
}
.wrap-evento .entradas-precios .calendar-fix .precio {
    margin-top: 0 !important;
}

.wrap-evento .entradas-precios .calendar-fix .button-book {
    margin-top: 0;
    margin-right: 20px;
    margin-right: 0;
    padding: 0 10px;
}

.Carrusel-banner {
    position: relative;
}

footer.footer-new .container-custom-tm {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
footer.footer-new .container-custom-tm > span {
    display: flex;
}
footer.footer-new .container-custom-tm > span > a {
    display: block;
    margin-right: 20px;
}
.login_info{
    display: flex;
    width: 100%;
    background-color: rgba(77, 191, 192,0.2);
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 40px;
    padding-left: 30px;
    border-radius: 6px;
    color: #002D5A;
    align-items: center;
    gap: 10px;
  }
  
  .login_alert{
    display: flex;
    width: 100%;
    background-color: rgba(249,162,0,0.2);
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 40px;
    padding-left: 30px;
    border-radius: 6px;
    color: #002D5A;
    align-items: center;
    gap: 10px;
  }
  
  .px-5{
    padding-left: 23px !important;
    padding-right: 23px !important;
  }

._ul2{
    list-style: none !important;
}
._ul2 li{
    padding-left: 10px;
}
.pill{
    border-radius: 6px;
    display:inline-flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    min-width: 69px;
    color: #5CC8CA;
    border: 1px solid #5CC8CA;
    background: #FFF;
}

.popuppromoIMage{
    display: flex;
    padding: 0 !important;
    border-radius: 8px;
}
.popuppromoIMage .swal2-close:focus{
    box-shadow: none;
}

.popuppromoIMage .imgpromo{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    color: white;
    padding: 20px;
    font-size: 20px;
    font-weight: 500;
}
.popuppromoIMage .textpromo{
    padding: 20px 30px;
    text-align: left;
    font-size: 14px;
    font-family: var(--font-family-sans-serif);
    color: #000;
    line-height: 20px;
}
.popuppromoIMage .textpromo h2{
    font-size: 22px;
    margin-top: 0;
}
@media (min-width: 741px) {
    .popuppromoIMage {
        width: 854px !important;
    }
    .popuppromoIMage .imgpromo{
        width: 350px;
        border-radius: 8px 0 0 8px !important;
        background-position: 90% 0% !important;
    }
    .popuppromoIMage .textpromo{
        width: calc(100% - 350px);
    }
}
@media (max-width: 740px) {
    .popuppromoIMage .swal2-html-container{
        flex-direction: column;
    }
    .popuppromoIMage{
        width: calc(100vw - 60px);
    }
    .popuppromoIMage .imgpromo{
        height: 290px;
        background-position: 0% 70% !important;
        border-radius: 8px 8px 0 0;
    }
    .popuppromoIMage img{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        
    }
}
.popuppromoIMage .swal2-content{
    display: flex;
    padding: 0;
}
.popuppromoIMage .swal2-html-container{
    display: flex !important;
    min-height: 388px;
}
.popuppromoIMage .swal2-close{
    display: block !important;
    box-shadow: none;
    font-size: 30px;
}
.subtitle_payguest{
    display: flex;
    font-size: 13px;
    font-weight: 400;
    align-items: center;
    margin-top: 8px;
}